import { useSearchCookbooksLazyQuery } from "../../generated/graphql";
import { SmartAutoComplete } from "../SmartAutoComplete";

type RecipeCookbookInputProps = {
  recipeId: string;
};

export function RecipeCookbookInput({ recipeId }: RecipeCookbookInputProps) {
  return (
    <SmartAutoComplete
      useLazyQuery={useSearchCookbooksLazyQuery}
      onSearchTransform={(x) => ({ search: `%${x}%` })}
      toOptions={(data) =>
        data.cookbooks.map((c) => ({ ...c, label: c.name, value: c.id }))
      }
      handleSelect={(selected) => console.log(selected)}
    />
  );
}
