import React, { useMemo } from "react";
import { Box, FlatList, HStack, Icon, Pressable, Text } from "native-base";
import { ListRenderItemInfo } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { AddInput } from "../../components/AddInput";
import {
  CategoryFragment,
  GetCategoriesDocument,
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useUpdateItemCategoryMutation,
} from "../../generated/graphql";
import { ShoppingListRootStackParamList } from "./ShoppingListStack";
import { sortBy } from "lodash";

export function ItemCategorySelection({
  navigation,
  route,
}: StackScreenProps<ShoppingListRootStackParamList, "ItemCategorySelection">) {
  const itemId = route?.params?.itemId;
  const itemName = route.params.itemName;
  const currentCategory = route.params.currentCategory;

  if (!itemId) {
    navigation.navigate("ShoppingListHome");
  }

  const [updateItemCategory] = useUpdateItemCategoryMutation();

  const { data } = useGetCategoriesQuery();
  const sortedCategories = sortBy(data?.category, (value) => value.name);

  const [addNewCategory] = useCreateCategoryMutation({
    refetchQueries: [{ query: GetCategoriesDocument }],
  });

  function addNewCategoryWithName(newCategoryName: string) {
    return addNewCategory({ variables: { name: newCategoryName } });
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: `Pick a category for ${itemName}`,
    });
  });

  function renderHeader() {
    return (
      <AddInput
        onSubmit={(newItemName) => addNewCategoryWithName(newItemName)}
      />
    );
  }

  function onSelectCategory(category: CategoryFragment) {
    return updateItemCategory({
      variables: { ingredient_id: itemId, category_id: category.id },
    }).then(() => navigation.pop());
  }

  const DefaultIcon = useMemo(
    () => (
      <Icon
        as={MaterialIcons}
        color="trueGray.400"
        mr="1"
        size="48px"
        name="favorite"
      />
    ),
    []
  );

  const SelectedIcon = useMemo(
    () => (
      <Icon
        as={MaterialIcons}
        color="tertiary.400"
        mr="1"
        size="48px"
        name="check"
      />
    ),
    []
  );

  function renderItem({ item }: ListRenderItemInfo<CategoryFragment>) {
    const isSelected = currentCategory === item.name;
    return (
      <Pressable onPress={() => onSelectCategory(item)}>
        <Box
          borderBottomWidth="1"
          _dark={{
            borderColor: "gray.600",
          }}
          borderColor="coolGray.200"
          pl="4"
          pr="5"
          py="2"
        >
          <HStack space={3} justifyContent="start" alignItems="center">
            {isSelected ? SelectedIcon : DefaultIcon}
            <Text
              _dark={{
                color: "warmGray.50",
              }}
              color="coolGray.800"
              bold
            >
              {item.name}
            </Text>
          </HStack>
        </Box>
      </Pressable>
    );
  }

  return (
    <FlatList
      ListHeaderComponent={renderHeader()}
      data={sortedCategories}
      renderItem={renderItem}
    />
  );
}
