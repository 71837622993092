import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Pressable,
  ScrollView,
  VStack,
  Text,
} from "native-base";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { MealPlanStackParamList } from "./MealPlanStack";
import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import { SelectableCalendar } from "../../components/calendar/SelectableCalendar";
import dayjs from "dayjs";
import { PopoverWrapper } from "../../components/popover/PopoverWrapper";
import {
  Meal_Plans_Set_Input,
  Scalars,
  useGetMealPlanByIdQuery,
  useUpdateMealPlanMutation,
} from "../../generated/graphql";
import { getAllDays } from "../../components/calendar/calendarUtils";
import { Counter } from "../../components/mealplan/Counter";
import { standardMealNames } from "../../utils/constants";

export function MealPlanCountSelection({
  navigation,
  route: { params: mealPlanId },
}: StackScreenProps<MealPlanStackParamList, "Meal Plan Wizard">) {
  const { data } = useGetMealPlanByIdQuery({
    variables: { id: mealPlanId.mealPlanId },
  });
  const startDay = data?.meal_plans_by_pk?.starts_on
    ? dayjs(data?.meal_plans_by_pk?.starts_on)
    : dayjs();
  const endDay = data?.meal_plans_by_pk?.ends_on
    ? dayjs(data?.meal_plans_by_pk?.ends_on)
    : dayjs();
  const dayFormat = "ddd, MMM D";
  const dayStorageFormat = "YYYY-MM-DD";
  const allDays = getAllDays(startDay, endDay);

  const [updateMealPlan, { loading: updateMealPlanLoading }] =
    useUpdateMealPlanMutation();
  const handleUpdateDate = (startsOn?: dayjs.Dayjs, endsOn?: dayjs.Dayjs) => {
    const setInput: Meal_Plans_Set_Input = {
      starts_on: startsOn,
      ends_on: endsOn,
    };
    updateMealPlan({
      variables: {
        id: mealPlanId.mealPlanId,
        setInput,
      },
    });
  };
  const handleUpdateCategoryCount = (
    day: dayjs.Dayjs,
    meal: string,
    updatedCount: number
  ) => {
    const setInput: Meal_Plans_Set_Input = {
      category_counts: {
        ...data?.meal_plans_by_pk?.category_counts,
        [day.format(dayStorageFormat)]: {
          ...data?.meal_plans_by_pk?.category_counts[
            day.format(dayStorageFormat)
          ],
          [meal]: updatedCount,
        },
      },
    };
    updateMealPlan({
      variables: {
        id: mealPlanId.mealPlanId,
        setInput,
      },
    });
  };

  const handleUpdateDefaultCategoryCount = (
    meal: string,
    updatedCount: number
  ) => {
    const allDayDefault = allDays.reduce<Scalars["jsonb"]>((acc, day) => {
      return {
        ...acc,
        [day.format(dayStorageFormat)]: {
          ...data?.meal_plans_by_pk?.category_counts[
            day.format(dayStorageFormat)
          ],
          [meal]: updatedCount,
        },
      };
    }, {});

    const setInput: Meal_Plans_Set_Input = {
      category_counts: {
        ...data?.meal_plans_by_pk?.category_counts,
        ...allDayDefault,
      },
    };
    updateMealPlan({
      variables: {
        id: mealPlanId.mealPlanId,
        setInput,
      },
    });
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          mt="2"
          mr="2"
          onPress={() =>
            navigation.navigate("Meal Plan Recipe Selection", {
              mealPlanId: mealPlanId.mealPlanId,
            })
          }
        >
          Create it!
        </Button>
      ),
    });
  }, [navigation]);

  return (
    <ScrollView contentContainerStyle={{ alignItems: "center" }}>
      <Box
        flex="1"
        alignItems="center"
        justifyContent="center"
        safeArea
        m="4"
        w={{
          sm: "75%",
          md: "60%",
          lg: "60%",
          xl: "50%",
        }}
        // backgroundColor="white"
        // rounded="20"
      >
        <VStack space={4} justifyContent="center" width="100%">
          <PopoverWrapper
            headerText={"Select Range"}
            popoverTrigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  <Heading mt="2" size="md" alignItems="center">
                    {startDay.format(dayFormat)} - {endDay.format(dayFormat)}{" "}
                    <Icon as={FontAwesome} name="pencil" />
                  </Heading>
                </Pressable>
              );
            }}
          >
            <SelectableCalendar
              onSelectStartDate={(start) => handleUpdateDate(start, endDay)}
              onSelectEndDate={(end) => handleUpdateDate(startDay, end)}
              startDate={startDay}
              endDate={endDay}
            />
          </PopoverWrapper>
          <Box backgroundColor="white" rounded="xl" py={4} px={8}>
            <HStack space={4} justifyContent="space-between">
              {standardMealNames.map((meal) => {
                const firstDayCategoryCounts =
                  data?.meal_plans_by_pk?.category_counts[
                    startDay.format(dayStorageFormat)
                  ] || {};
                const firstDayMealOverrides = firstDayCategoryCounts[meal];
                return (
                  <VStack key={meal} space={2} alignItems="center">
                    <Heading size="sm">{meal}</Heading>
                    <Counter
                      value={firstDayMealOverrides ?? 2}
                      setValue={(updated) =>
                        handleUpdateDefaultCategoryCount(meal, updated)
                      }
                      increment={1}
                      loading={updateMealPlanLoading}
                    />
                  </VStack>
                );
              })}
            </HStack>
          </Box>
          {allDays.map((day) => {
            return (
              <VStack key={day.unix()}>
                <Heading size="md" mb="2">
                  {day.format(dayFormat)}
                </Heading>

                <VStack space={2}>
                  {standardMealNames.map((meal) => {
                    const dayCounts =
                      data?.meal_plans_by_pk?.category_counts[
                        day.format(dayStorageFormat)
                      ] || {};
                    const mealCount = dayCounts[meal] ?? 2;
                    return (
                      <HStack
                        key={`${day.unix()}-${meal}`}
                        justifyContent="space-between"
                        alignItems="center"
                        backgroundColor="white"
                        py={4}
                        px={8}
                        rounded="xl"
                        space={6}
                      >
                        <Heading size="sm">{meal}</Heading>
                        <Counter
                          increment={1}
                          value={mealCount}
                          setValue={(numServings) => {
                            handleUpdateCategoryCount(day, meal, numServings);
                          }}
                          loading={updateMealPlanLoading}
                        />
                      </HStack>
                    );
                  })}
                </VStack>
              </VStack>
            );
          })}
        </VStack>
      </Box>
    </ScrollView>
  );
}
