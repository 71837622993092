import { ShoppingListDetails } from "./ShoppingListDetails";
import { ItemCategorySelection } from "./ItemCategorySelection";
import { ItemStoreSelection } from "./ItemStoreSelection";
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

export type ShoppingListRootStackParamList = {
  ShoppingListHome: undefined;
  ShoppingListItemForm: { itemId: string };
  ShoppingListDetails: { shoppingListId: string };
  ItemCategorySelection: {
    itemId: string;
    itemName?: string;
    currentCategory?: string;
  };
  ItemStoreSelection: { itemId: string };
};

const Stack = createStackNavigator<ShoppingListRootStackParamList>();

export function ShoppingListStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
      }}
    >
      <Stack.Screen
        name="ShoppingListDetails"
        component={ShoppingListDetails}
        options={{
          title: "Shopping List",
        }}
      />
      {/*<Stack.Screen*/}
      {/*  name="ShoppingListHome"*/}
      {/*  component={ShoppingListOverview}*/}
      {/*  options={{*/}
      {/*    title: "Shopping List",*/}
      {/*    headerRight: () => (*/}
      {/*      <IconButton*/}
      {/*        mr={2}*/}
      {/*        borderRadius="sm"*/}
      {/*        variant="solid"*/}
      {/*        icon={*/}
      {/*          <Icon as={Feather} name="plus" size="sm" color="warmGray.50" />*/}
      {/*        }*/}
      {/*        onPress={() => navigation.navigate("Shopping List Edit")}*/}
      {/*      />*/}
      {/*    ),*/}
      {/*  }}*/}
      {/*/>*/}
      <Stack.Screen
        name="ItemCategorySelection"
        component={ItemCategorySelection}
        options={{
          title: "Select a Category",
        }}
      />
      <Stack.Screen
        name="ItemStoreSelection"
        component={ItemStoreSelection}
        options={{
          title: "Select a Store",
        }}
      />
    </Stack.Navigator>
  );
}
