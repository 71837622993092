import { VStack, HStack } from "native-base";
import React, { useState } from "react";
import { MealItem } from "./MealItem";
import { Counter } from "./Counter";
import { MealPlanRecipeCoreFragment } from "../../generated/graphql";

type MealPlanRowProps = {
  mealPlanRecipe: MealPlanRecipeCoreFragment;
  onServingsChange: (multiplier: number) => void;
};

export function MealPlanRow({
  mealPlanRecipe,
  onServingsChange,
}: MealPlanRowProps) {
  const [servings, setServings] = useState(
    mealPlanRecipe.recipe.servings * mealPlanRecipe.multiplier
  );

  return (
    <VStack
      space={4}
      alignItems="center"
      justifyContent="space-between"
      mb={1}
      mt={1}
    >
      <MealItem
        mealPlanRecipe={mealPlanRecipe}
        backgroundColor="tertiary.200"
      />
      <Counter
        increment={0.25}
        value={servings}
        setValue={(numServings) => {
          const multiplier = numServings / mealPlanRecipe.recipe.servings;
          onServingsChange(multiplier);
          setServings(numServings);
        }}
      />
    </VStack>
  );
}
