import React, { useState } from "react";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import {
  Box,
  Button,
  Heading,
  Input,
  ScrollView,
  TextArea,
  VStack,
} from "native-base";
import { RecipeRootStackParamList } from "./RecipeStack";
import {
  useInsertRecipeIngredientMutation,
  useInsertRecipeMutation,
} from "../../generated/graphql";

export function RecipeImportWizard({
  navigation,
}: StackScreenProps<RecipeRootStackParamList, "RecipeImport">) {
  // State
  const [title, setTitle] = useState<string>("");
  const [ingredients, setIngredients] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // Mutation hooks
  const [insertRecipe] = useInsertRecipeMutation();
  const [insertRecipeIngredient] = useInsertRecipeIngredientMutation();

  async function handleSubmit() {
    setLoading(true);
    const recipeData = await insertRecipe({
      variables: {
        name: title,
        servings: 4,
      },
    });
    const recipeId = recipeData.data?.insert_recipes?.returning[0]?.id;

    await Promise.all(
      ingredients.split(/\r?\n/).map(async (ingredient) => {
        // Ignore empty lines
        if (ingredient.length < 1) {
          return null;
        }

        await insertRecipeIngredient({
          variables: {
            recipe_id: recipeId,
            raw_text: ingredient,
          },
        });
      })
    );

    setLoading(false);
    navigation.navigate("RecipeDetails", {
      recipeId: recipeId,
      edit: true,
    });
  }

  return (
    <ScrollView>
      <Box flex={1}>
        <Heading size="lg" px="4" mt="4">
          Recipe Import
        </Heading>
        <Box
          maxW={600}
          p={5}
          rounded="xl"
          shadow={4}
          bgColor="white"
          mt="2"
          mx="4"
        >
          <VStack alignItems="left" space="2">
            <Heading size="md" mt="4">
              Title
            </Heading>
            <Input
              placeholder="The recipe's title"
              w="100%"
              onChangeText={setTitle}
            />
            <Heading size="md" mt="4">
              Ingredients
            </Heading>
            <TextArea
              placeholder="Paste or type one ingredient per line"
              h={80}
              w="100%"
              onChangeText={setIngredients}
            />
            <Button isLoading={loading} onPress={handleSubmit}>
              Import Recipe
            </Button>
          </VStack>
        </Box>
      </Box>
    </ScrollView>
  );
}
