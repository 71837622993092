import React from "react";
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import {
  Box,
  Center,
  Divider,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import { FlatList, ListRenderItemInfo } from "react-native";
import { StarRating } from "../../components/recipe/StarRating";
import { RecipeRootStackParamList } from "./RecipeStack";
import {
  GetRecipeCoreFragment,
  useGetRecipesQuery,
} from "../../generated/graphql";

type RecipeListProps = {
  navigation: StackNavigationProp<RecipeRootStackParamList, "RecipeListHome">;
};

export function RecipeListHome({ navigation }: RecipeListProps) {
  // const [getRecipes, { loading, error, data }] = useGetRecipesLazyQuery();
  const { loading, error, data } = useGetRecipesQuery();

  if (loading) {
    return (
      <Center w="64" h="20" rounded="md">
        <Text>Loading...</Text>
      </Center>
    );
  }
  if (error) {
    console.error(error);
    return (
      <Center w="64" h="20" rounded="md">
        <Text>Error!</Text>
      </Center>
    );
  }

  if (!data) {
    return <Box />;
  }

  const listData = data.recipes;

  const renderItem = ({ item }: ListRenderItemInfo<GetRecipeCoreFragment>) => (
    <Box mt={2} mx={2}>
      <Pressable
        onPress={() => {
          return navigation.navigate("RecipeDetails", { recipeId: item.id });
        }}
        bg="white"
        rounded="lg"
      >
        <Box pl="4" pr="5" py="2">
          <VStack alignItems="flex-start">
            <Text color="coolGray.800" _dark={{ color: "warmGray.50" }} bold>
              {item.name}
            </Text>
            <HStack
              justifyContent="space-between"
              alignItems="center"
              space={4}
            >
              <Text color="coolGray.800" _dark={{ color: "warmGray.50" }}>
                {item.servings} Servings
              </Text>
              <HStack>
                <StarRating rating={item.rating} size="xs" />
              </HStack>
            </HStack>
          </VStack>
        </Box>
      </Pressable>
      <Divider />
    </Box>
  );

  return (
    <Box flex={1}>
      <ScrollView>
        <FlatList data={listData} renderItem={renderItem} />
      </ScrollView>
    </Box>
  );
}
