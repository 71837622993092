import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Progress,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { MealPlanStackParamList } from "./MealPlanStack";
import {
  GetItemsForRangeDocument,
  GetMealPlanWithRecipesDocument,
  GetRecipeCoreWithIngredientsFragment,
  Meal_Plans_Recipes_Insert_Input,
  MealPlanRecipeCoreFragment,
  useDeleteMealPlanRecipeMutation,
  useGetMealPlanWithRecipesQuery,
  useInsertMealPlanRecipeItemSourcesMutation,
  useUpdateMealPlanRecipeServingsMutation,
} from "../../generated/graphql";
import dayjs from "dayjs";
import { getAllDays } from "../../components/calendar/calendarUtils";
import { Counter } from "../../components/mealplan/Counter";
import React, { useEffect, useMemo, useState } from "react";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { RecipeOnlySearchSidebar } from "../../components/mealplan/RecipeOnlySearchSidebar";
import { sortBy } from "lodash";
import {
  dayFormat,
  dayStorageFormat,
  standardMealNames,
} from "../../utils/constants";

export function MealPlanRecipeSelection({
  navigation,
  route: { params: mealPlanId },
}: StackScreenProps<MealPlanStackParamList, "Meal Plan Recipe Selection">) {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button mt="2" mr="2">
          Done!
        </Button>
      ),
    });
  }, [navigation]);

  const { data } = useGetMealPlanWithRecipesQuery({
    variables: { id: mealPlanId.mealPlanId },
  });
  const [insertMealPlanRecipeItemSources, { data: insertData }] =
    useInsertMealPlanRecipeItemSourcesMutation();

  const [updateServings, { data: updateServingData }] =
    useUpdateMealPlanRecipeServingsMutation();

  const [deleteMealPlanRecipe, { loading }] = useDeleteMealPlanRecipeMutation({
    refetchQueries: [GetMealPlanWithRecipesDocument, GetItemsForRangeDocument],
  });

  const recipeData = useMemo(
    () => data?.meal_plans_by_pk?.meal_plan_recipes,
    [data]
  );

  const [recipes, setRecipes] = useState<MealPlanRecipeCoreFragment[]>(
    sortBy(recipeData, (x: MealPlanRecipeCoreFragment) => x.created_at) || []
  );

  useEffect(() => {
    if (recipeData) {
      setRecipes(
        sortBy(recipeData, (x: MealPlanRecipeCoreFragment) => x.created_at)
      );
    }
  }, [recipeData]);

  useEffect(() => {
    if (
      insertData?.insert_meal_plans_recipes?.returning &&
      insertData?.insert_meal_plans_recipes?.returning.length > 0
    ) {
      setRecipes(
        sortBy(
          [...recipes, ...insertData.insert_meal_plans_recipes.returning],
          (x: MealPlanRecipeCoreFragment) => x.created_at
        )
      );
    }
  }, [insertData]);

  const recipeServings = recipes.reduce((acc, curr) => {
    return acc + curr.recipe.servings * curr.multiplier;
  }, 0);
  const startDay = data?.meal_plans_by_pk?.starts_on
    ? dayjs(data?.meal_plans_by_pk?.starts_on)
    : dayjs();
  const endDay = data?.meal_plans_by_pk?.ends_on
    ? dayjs(data?.meal_plans_by_pk?.ends_on)
    : dayjs();
  const allDays = getAllDays(startDay, endDay);
  const categoryCounts = data?.meal_plans_by_pk?.category_counts || {};
  const mealsNeeded: number = allDays.reduce((acc, curr) => {
    const override: { [meal: string]: number } =
      categoryCounts[curr.format(dayStorageFormat)] || {};
    const dayMealCounts = standardMealNames
      .map((meal) => override[meal] ?? 2)
      .reduce((acc, curr) => curr + acc, 0);
    return acc + dayMealCounts;
  }, 0);
  const progress = Math.min(
    Math.round((recipeServings / mealsNeeded) * 100),
    100
  );

  const handleRecipeSelect = (recipe: GetRecipeCoreWithIngredientsFragment) => {
    const ingredients = recipe.recipe_ingredients.map((i) => ({
      recipe_ingredient_id: i.id,
      desired_at: startDay.format(dayStorageFormat),
    }));
    const mealPlansRecipesInsertInput: Meal_Plans_Recipes_Insert_Input = {
      meal_plan_id: mealPlanId.mealPlanId,
      recipe_id: recipe.id,
      day: startDay,
      item_sources: {
        data: ingredients, // TODO does this also set meal_plan_recipe_id ?
      },
    };
    return insertMealPlanRecipeItemSources({
      variables: { meal_plan_recipes: [mealPlansRecipesInsertInput] },
      refetchQueries: [GetItemsForRangeDocument],
    });
  };

  return (
    <Stack
      h="100%"
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "center", md: "stretch" }}
    >
      <Box
        flex="1"
        justifyContent="center"
        alignItems="center"
        safeArea
        p="4"
        w={{
          sm: "75%",
          md: "60%",
          lg: "60%",
          xl: "50%",
        }}
      >
        <Heading size="md" mb="2">
          {startDay.format(dayFormat)} - {endDay.format(dayFormat)}{" "}
          <IconButton
            onPress={() =>
              navigation.navigate("Meal Plan Wizard", {
                mealPlanId: mealPlanId.mealPlanId,
              })
            }
            icon={<Icon as={FontAwesome} name="pencil-square-o" size="sm" />}
          />
        </Heading>
        <HStack alignItems="center" justifyContent="center" w="100%">
          <Box w="90%" maxW="400" mb="2">
            <Progress value={progress} mx="4" />
          </Box>
          <Button
            size="sm"
            display={{ base: "flex", md: "none" }}
            onPress={() =>
              navigation.navigate("FindRecipe", {
                mealPlanId: mealPlanId.mealPlanId,
              })
            }
          >
            Add
          </Button>
        </HStack>

        <Text mb="2">
          {recipeServings}/{mealsNeeded} meals selected
        </Text>
        <ScrollView>
          <VStack space={2}>
            {recipes.map((recipe) => {
              return (
                <HStack
                  key={`${recipe.id}`}
                  justifyContent="space-between"
                  alignItems="center"
                  space={4}
                >
                  <HStack
                    py={4}
                    px={8}
                    justifyContent="space-between"
                    alignItems="center"
                    rounded="xl"
                    backgroundColor="white"
                    flex={1}
                    space={2}
                  >
                    {(recipe.recipe.recipe_ingredients_aggregate.aggregate
                      ?.count ?? 0) <= 0 && (
                      <IconButton
                        colorScheme={"warning"}
                        icon={
                          <Icon as={Feather} name="alert-triangle" size="sm" />
                        }
                      />
                    )}
                    <Heading size="sm">
                      {recipe.recipe.name} (
                      {recipe.multiplier * recipe.recipe.servings})
                    </Heading>
                    <Counter
                      increment={0.25}
                      value={recipe.multiplier}
                      setValue={(multiplier) => {
                        updateServings({
                          variables: { id: recipe.id, multiplier },
                          refetchQueries: [GetMealPlanWithRecipesDocument],
                        });
                      }}
                    />
                  </HStack>
                  <IconButton
                    borderRadius="sm"
                    variant="ghost"
                    icon={<Icon as={Feather} name="trash" size="sm" />}
                    disabled={loading}
                    onPress={() =>
                      deleteMealPlanRecipe({
                        variables: { id: recipe.id },
                      })
                    }
                  />
                </HStack>
              );
            })}
          </VStack>
        </ScrollView>
      </Box>
      <Box
        flex={1}
        display={{ base: "none", md: "flex" }}
        mt={4}
        mr={{
          sm: 4,
          xl: 24,
        }}
        mb={4}
      >
        <RecipeOnlySearchSidebar onPress={handleRecipeSelect} />
      </Box>
    </Stack>
  );
}
