import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  HStack,
  Input,
  Link,
  Text,
  VStack,
} from "native-base";
import loginbg from "../../assets/bg/dalle-vegetable-watercolors.jpg";
import { ImageBackground, useWindowDimensions } from "react-native";
import { useSignInEmailPassword } from "@nhost/react";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { UnauthenticatedParamList } from "../../AppCore";

export function Login({
  navigation,
}: StackScreenProps<UnauthenticatedParamList, "login">) {
  const { height } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    signInEmailPassword,
    needsEmailVerification,
    isLoading,
    isError,
    error,
  } = useSignInEmailPassword();

  return (
    <ImageBackground
      source={{ uri: loginbg, height: height }}
      resizeMode="cover"
    >
      <Center w="100%">
        <Box
          safeArea
          p="8"
          m="8"
          w="90%"
          maxW="290"
          backgroundColor="white"
          rounded="20"
        >
          <Heading
            size="lg"
            fontWeight="600"
            color="coolGray.800"
            _dark={{
              color: "warmGray.50",
            }}
          >
            Welcome
          </Heading>

          {needsEmailVerification && (
            <Alert mt="4" w="100%" status="error">
              <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} justifyContent="space-between">
                  <HStack space={2} flexShrink={1}>
                    <Alert.Icon mt="1" />
                    <Text fontSize="md" color="coolGray.800">
                      Please verify your email to continue
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
            </Alert>
          )}
          {isError && (
            <Alert mt="4" w="100%" status="error">
              <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} justifyContent="space-between">
                  <HStack space={2} flexShrink={1}>
                    <Alert.Icon mt="1" />
                    <Text fontSize="md" color="coolGray.800">
                      {error?.message}
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
            </Alert>
          )}

          <VStack space={3} mt="5">
            <FormControl>
              <FormControl.Label>Email</FormControl.Label>
              <Input onChangeText={setEmail} />
            </FormControl>
            <FormControl>
              <FormControl.Label>Password</FormControl.Label>
              <Input
                onChangeText={setPassword}
                type="password"
                onSubmitEditing={() => signInEmailPassword(email, password)}
              />
              <Button
                variant="link"
                _text={{
                  fontSize: "xs",
                  fontWeight: "500",
                  color: "indigo.500",
                }}
                alignSelf="flex-end"
                mt="1"
              >
                Forgot Password?
              </Button>
            </FormControl>
            <Button
              mt="2"
              colorScheme="indigo"
              disabled={isLoading}
              onPress={() => signInEmailPassword(email, password)}
            >
              Sign in
            </Button>
            <HStack mt="6" justifyContent="center" alignItems="center">
              <Text
                fontSize="sm"
                color="coolGray.600"
                _dark={{
                  color: "warmGray.200",
                }}
              >
                I&apos;m a new user.{" "}
              </Text>
              <Button
                variant="link"
                _text={{
                  color: "indigo.500",
                  fontWeight: "medium",
                  fontSize: "sm",
                }}
                onPress={() => navigation.navigate("signup")}
              >
                Sign Up
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Center>
    </ImageBackground>
  );
}
