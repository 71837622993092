import { Box, Popover } from "native-base";
import React, { ReactNode } from "react";
import { GestureResponderEvent } from "react-native";

type SelectableCalendarPopoverProps = {
  popoverTrigger: (triggerProps: {
    onPress: (event: GestureResponderEvent) => void;
  }) => JSX.Element;
  headerText?: string;
  children: ReactNode;
};

export function PopoverWrapper({
  popoverTrigger,
  headerText,
  children,
}: SelectableCalendarPopoverProps) {
  return (
    <Box w="100%" alignItems="center">
      <Popover trigger={popoverTrigger}>
        <Popover.Content>
          <Popover.Arrow />
          <Popover.CloseButton />
          {headerText && <Popover.Header>{headerText}</Popover.Header>}
          <Popover.Body>{children}</Popover.Body>
        </Popover.Content>
      </Popover>
    </Box>
  );
}
