import { Icon, useTheme } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { Calendar, DateData } from "react-native-calendars";
import React, { useEffect, useState } from "react";
import { MarkedDates } from "react-native-calendars/src/types";
import dayjs from "dayjs";
import { convertToMarkedDates } from "./calendarUtils";

type SelectableCalendarProps = {
  onSelectStartDate: (startDate: dayjs.Dayjs) => void;
  onSelectEndDate: (startDate: dayjs.Dayjs) => void;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

export const SelectableCalendar = React.memo(_SelectableCalendar);

function _SelectableCalendar({
  onSelectStartDate,
  onSelectEndDate,
  startDate,
  endDate,
}: SelectableCalendarProps) {
  const theme = useTheme();
  const [markedDates, setMarkedDates] = useState<MarkedDates>({});
  const [pickedStart, setPickedStart] = useState(!!startDate || false);

  const handleDayPress = (day: DateData) => {
    const selectedDay = dayjs(day.dateString);

    if (
      // between existing dates
      selectedDay.isBefore(endDate) &&
      selectedDay.isAfter(startDate)
    ) {
      // alternate between whether a start or end was picked last
      if (pickedStart) {
        onSelectEndDate(selectedDay);
      } else {
        onSelectStartDate(selectedDay);
      }
      setPickedStart(pickedStart!);
    } else if (
      // before the start date
      selectedDay.isBefore(startDate)
    ) {
      onSelectStartDate(selectedDay);
      setPickedStart(true);
    } else if (
      // after end date
      selectedDay.isAfter(endDate)
    ) {
      onSelectEndDate(selectedDay);
      setPickedStart(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      const converted = convertToMarkedDates(
        startDate,
        endDate,
        theme.colors.primary["500"]
      );
      setMarkedDates(converted);
    } else if (startDate) {
      setMarkedDates(
        convertToMarkedDates(startDate, startDate, theme.colors.primary["500"])
      );
    }
  }, [startDate, endDate]);

  return (
    <Calendar
      markingType={"period"}
      markedDates={markedDates}
      // Initially visible month. Default = now
      // initialDate={"2012-03-01"}
      // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
      // minDate={"2012-05-10"}
      // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
      // maxDate={"2012-05-30"}
      // Handler which gets executed on day press. Default = undefined
      onDayPress={(day) => {
        handleDayPress(day);
      }}
      // Handler which gets executed on day long press. Default = undefined
      // onDayLongPress={(day) => {
      //   console.log("selected day", day);
      // }}
      // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
      // monthFormat={"yyyy MM"}
      // Handler which gets executed when visible month changes in calendar. Default = undefined
      // onMonthChange={(month) => {
      //   console.log("month changed", month);
      // }}
      // Hide month navigation arrows. Default = false
      // hideArrows={true}
      // Replace default arrows with custom ones (direction can be 'left' or 'right')
      renderArrow={(direction) => (
        <Icon as={MaterialIcons} name={`arrow-${direction}`} size="lg" />
      )}
      // Do not show days of other months in month page. Default = false
      // hideExtraDays={true}
      // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
      // day from another month that is visible in calendar page. Default = false
      // disableMonthChange={true}
      // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
      // firstDay={1}
      // Hide day names. Default = false
      // hideDayNames={true}
      // Show week numbers to the left. Default = false
      // showWeekNumbers={true}
      // Handler which gets executed when press arrow icon left. It receive a callback can go back month
      onPressArrowLeft={(subtractMonth) => subtractMonth()}
      // Handler which gets executed when press arrow icon right. It receive a callback can go next month
      onPressArrowRight={(addMonth) => addMonth()}
      // Disable left arrow. Default = false
      // disableArrowLeft={true}
      // Disable right arrow. Default = false
      // disableArrowRight={true}
      // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
      disableAllTouchEventsForDisabledDays={true}
      // Replace default month and year title with custom one. the function receive a date as parameter
      // renderHeader={(date) => {
      //   /*Return JSX*/
      // }}
      // Enable the option to swipe between months. Default = false
      enableSwipeMonths={true}
    />
  );
}
