import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Heading,
  HStack,
  Icon,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import {
  GetItemsForRangeDocument,
  GetRecipeCoreWithIngredientsFragment,
  Meal_Plans_Recipes_Insert_Input,
  MealPlanRecipeCoreFragment,
  useGetMealPlanWithRecipesQuery,
  useInsertMealPlanRecipeItemSourcesMutation,
  useUpdateMealPlanMutation,
  useUpdateMealPlanRecipeServingsMutation,
} from "../../generated/graphql";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { MealPlanStackParamList } from "./MealPlanStack";
import { FontAwesome } from "@expo/vector-icons";
import { getMealPlanTitle } from "../../components/mealplan/utils";
import { SelectableCalendar } from "../../components/calendar/SelectableCalendar";
import { PopoverWrapper } from "../../components/popover/PopoverWrapper";
import dayjs from "dayjs";
import { recipesForRendering } from "./utils";
import { getAllDays } from "../../components/calendar/calendarUtils";
import { StandardBox } from "./StandardBox";
import { Dictionary } from "lodash";
import { MealPlanRow } from "../../components/mealplan/MealPlanRow";
import { RecipeSearchSidebar } from "../../components/mealplan/RecipeSearchSidebar";

export function MealPlanCreator({
  navigation,
  route: {
    params: { mealPlanId },
  },
}: StackScreenProps<MealPlanStackParamList, "MealPlanCreator">) {
  const { data: mealPlan } = useGetMealPlanWithRecipesQuery({
    variables: { id: mealPlanId },
  });

  const [updateMealPlan] = useUpdateMealPlanMutation();
  const [insertMealPlanRecipeItemSources, { data: insertData }] =
    useInsertMealPlanRecipeItemSourcesMutation();
  const [updateServings] = useUpdateMealPlanRecipeServingsMutation();

  const handleUpdateDate = (startsOn?: dayjs.Dayjs, endsOn?: dayjs.Dayjs) =>
    updateMealPlan({
      variables: {
        id: mealPlanId,
        setInput: { starts_on: startsOn, ends_on: endsOn },
      },
    });

  const mealPlanTitle = getMealPlanTitle(mealPlan?.meal_plans_by_pk);
  const starts = dayjs(mealPlan?.meal_plans_by_pk?.starts_on);
  const ends = dayjs(mealPlan?.meal_plans_by_pk?.ends_on);
  const allDays = getAllDays(starts, ends);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <PopoverWrapper
          headerText={"Select Range"}
          popoverTrigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <Heading mt="2" size="md" alignItems="center">
                  {mealPlanTitle} <Icon as={FontAwesome} name="pencil" />
                </Heading>
              </Pressable>
            );
          }}
        >
          <SelectableCalendar
            onSelectStartDate={(start) =>
              handleUpdateDate(start, mealPlan?.meal_plans_by_pk?.ends_on)
            }
            onSelectEndDate={(end) =>
              handleUpdateDate(mealPlan?.meal_plans_by_pk?.starts_on, end)
            }
            startDate={dayjs(mealPlan?.meal_plans_by_pk?.starts_on)}
            endDate={dayjs(mealPlan?.meal_plans_by_pk?.ends_on)}
          />
        </PopoverWrapper>
      ),
    });
  }, [navigation, mealPlanTitle]);

  const recipes = useMemo(
    () => mealPlan?.meal_plans_by_pk?.meal_plan_recipes,
    [mealPlan]
  );
  const [data, setData] = useState<MealPlanRecipeCoreFragment[]>(recipes || []);

  useEffect(() => {
    if (recipes) {
      setData(recipes);
    }
  }, [recipes]);

  useEffect(() => {
    if (
      insertData?.insert_meal_plans_recipes?.returning &&
      insertData?.insert_meal_plans_recipes?.returning.length > 0
    ) {
      setData([...insertData.insert_meal_plans_recipes.returning, ...data]);
    }
  }, [insertData]);

  const groupedData = useMemo(
    () => recipesForRendering(data, starts, ends),
    [data, starts, ends]
  );
  const recipeLookup: Dictionary<MealPlanRecipeCoreFragment> = data.reduce<
    Dictionary<MealPlanRecipeCoreFragment>
  >(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {}
  );

  const handleRecipeSelect = (
    recipe: GetRecipeCoreWithIngredientsFragment,
    day: string,
    duration: string,
    category: string
  ) => {
    const ingredients = recipe.recipe_ingredients.map((i) => ({
      recipe_ingredient_id: i.id,
      desired_at: mealPlan?.meal_plans_by_pk?.starts_on,
    }));
    const mealPlansRecipesInsertInput: Meal_Plans_Recipes_Insert_Input = {
      meal_plan_id: mealPlanId,
      recipe_id: recipe.id,
      day,
      duration,
      category,
      item_sources: {
        data: ingredients, // TODO does this also set meal_plan_recipe_id ?
      },
    };
    return insertMealPlanRecipeItemSources({
      variables: { meal_plan_recipes: [mealPlansRecipesInsertInput] },
      refetchQueries: [GetItemsForRangeDocument],
    });
  };

  // TODO what happens when you change the meal plan dates and remove a day that had meals on it?
  // TODO what happens when a recipe is on a meal plan and shopping list but the ingredients change?
  const breakfast = (!!groupedData && groupedData["breakfast"]) || [];
  const lunch = (!!groupedData && groupedData["lunch"]) || [];
  const dinner = (!!groupedData && groupedData["dinner"]) || [];
  const rowWidth = (1 / allDays.length) * 100;
  return (
    <HStack height="100%">
      <ScrollView height="100%" horizontal={true} style={{ flex: 1 }}>
        <HStack mx={4}>
          <VStack flex={1}>
            <HStack
              padding={{
                base: "4",
                md: "30",
                lg: "30",
              }}
            >
              {allDays.map((d) => {
                const displayedDate = d.format("dddd\nMMM D, YYYY");
                return (
                  <StandardBox width={`${rowWidth}%`}>
                    <Text>{displayedDate}</Text>
                  </StandardBox>
                );
              })}
            </HStack>
            <VStack
              flex={1}
              px={{
                base: "4",
                md: "30",
                lg: "30",
              }}
            >
              {breakfast.map((row) => {
                const x = row.map((recipe) => {
                  const mealPlanRecipe = recipe.mealPlanRecipeId
                    ? recipeLookup[recipe.mealPlanRecipeId]
                    : undefined;
                  return (
                    <StandardBox
                      width={`${rowWidth * recipe.duration}%`}
                      gap={recipe.gap}
                    >
                      {mealPlanRecipe && (
                        <MealPlanRow
                          mealPlanRecipe={mealPlanRecipe}
                          onServingsChange={(multiplier) =>
                            updateServings({
                              variables: { id: mealPlanRecipe.id, multiplier },
                            })
                          }
                        />
                      )}
                    </StandardBox>
                  );
                });
                return <HStack flex={1}>{x}</HStack>;
              })}
            </VStack>
            <VStack
              flex={1}
              px={{
                base: "4",
                md: "30",
                lg: "30",
              }}
            >
              {lunch.map((row) => {
                const x = row.map((cell) => {
                  const mealPlanRecipe = cell.mealPlanRecipeId
                    ? recipeLookup[cell.mealPlanRecipeId]
                    : undefined;
                  return (
                    <StandardBox
                      width={`${rowWidth * cell.duration}%`}
                      gap={cell.gap}
                    >
                      {mealPlanRecipe && (
                        <MealPlanRow
                          mealPlanRecipe={mealPlanRecipe}
                          onServingsChange={(multiplier) =>
                            updateServings({
                              variables: { id: mealPlanRecipe.id, multiplier },
                            })
                          }
                        />
                      )}
                    </StandardBox>
                  );
                });
                return <HStack flex={1}>{x}</HStack>;
              })}
            </VStack>
            <VStack
              flex={1}
              px={{
                base: "4",
                md: "30",
                lg: "30",
              }}
            >
              {dinner.map((row) => {
                const x = row.map((cell) => {
                  const mealPlanRecipe = cell.mealPlanRecipeId
                    ? recipeLookup[cell.mealPlanRecipeId]
                    : undefined;
                  return (
                    <StandardBox
                      width={`${rowWidth * cell.duration}%`}
                      gap={cell.gap}
                    >
                      {mealPlanRecipe && (
                        <MealPlanRow
                          mealPlanRecipe={mealPlanRecipe}
                          onServingsChange={(multiplier) =>
                            updateServings({
                              variables: { id: mealPlanRecipe.id, multiplier },
                            })
                          }
                        />
                      )}
                    </StandardBox>
                  );
                });
                return <HStack flex={1}>{x}</HStack>;
              })}
            </VStack>
          </VStack>
        </HStack>
      </ScrollView>
      <Box>
        <RecipeSearchSidebar
          onPress={(recipe, day, duration, meal) =>
            handleRecipeSelect(recipe, day, duration, meal)
          }
        />
      </Box>
    </HStack>
  );
}
