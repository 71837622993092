import { RecipeIngredientWithItemSourcesFragment } from "../../generated/graphql";
import React from "react";
import { RecipeIngredientRowEdit } from "./RecipeIngredientRowEdit";
import { RecipeIngredientRow } from "./RecipeIngredientRow";

type Props = {
  item: RecipeIngredientWithItemSourcesFragment;
  checked: boolean;
  loading: boolean;
  editMode: boolean;
  onPressDelete: (item: RecipeIngredientWithItemSourcesFragment) => void;
  onPressCheckbox: (item: RecipeIngredientWithItemSourcesFragment) => void;
};

export function RecipeIngredientWrapper(props: Props) {
  if (props.editMode) {
    return <RecipeIngredientRowEdit {...props} />;
  }

  return <RecipeIngredientRow {...props} />;
}
