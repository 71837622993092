import React from "react";
import {
  IngredientSearchFragment,
  useIngredientInfoLazyQuery,
} from "../../generated/graphql";
import { SmartAutoComplete } from "../SmartAutoComplete";

type IngredientSearchProps = {
  handleSelect: (ingredient: IngredientSearchFragment) => void;
  initialIngredient?: IngredientSearchFragment;
};

export const IngredientSearch = ({
  handleSelect,
  initialIngredient,
}: IngredientSearchProps) => {
  return (
    <SmartAutoComplete
      useLazyQuery={useIngredientInfoLazyQuery}
      onSearchTransform={(x) => ({ ingredientName: `%${x}%` })}
      toOptions={(data) =>
        data.ingredients.map((i) => ({ ...i, label: i.name, value: i.name }))
      }
      handleSelect={handleSelect}
      initialOptionDisplay={initialIngredient?.name}
    />
  );
};
