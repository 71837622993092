import { Box, HStack, Input, Radio, Text, VStack } from "native-base";
import { RecipeSearchResult } from "./RecipeSearchResult";
import React, { useEffect, useState } from "react";
import {
  GetRecipeCoreWithIngredientsFragment,
  useSearchRecipesByIngredientLazyQuery,
  useSearchRecipesLazyQuery,
} from "../../generated/graphql";
import { MaskedInput } from "../base/MaskedInput";

type RecipeSearchSidebarProps = {
  onPress: (
    recipe: GetRecipeCoreWithIngredientsFragment,
    day: string,
    duration: string,
    meal: string
  ) => Promise<unknown>;
};

export function RecipeSearchSidebar({ onPress }: RecipeSearchSidebarProps) {
  const [addDay, setAddDay] = useState("");
  const [duration, setDuration] = useState("");
  const [searchBy, setSearchBy] = useState<string>("recipe");
  const [meal, setMeal] = useState<string>("lunch");
  const [searchResults, setSearchResults] = useState<
    GetRecipeCoreWithIngredientsFragment[] | undefined
  >([]);

  const [searchRecipes, { data: byRecipeData }] = useSearchRecipesLazyQuery();
  const [searchRecipesByIngredient, { data: byIngredientData }] =
    useSearchRecipesByIngredientLazyQuery();

  useEffect(() => {
    if (searchBy === "recipe") {
      setSearchResults(byRecipeData?.recipes);
    } else if (searchBy === "ingredient") {
      setSearchResults(byIngredientData?.recipes);
    } else {
      setSearchResults([]);
    }
  }, [byRecipeData, byIngredientData, searchBy]);

  const doSearch = (text: string) => {
    const searchFunction =
      searchBy === "recipe" ? searchRecipes : searchRecipesByIngredient;
    return searchFunction({ variables: { search: `%${text}%` } });
  };
  return (
    <Box
      flex={1}
      mt={4}
      mr={{
        sm: 4,
        xl: 24,
      }}
      p={5}
      rounded="xl"
      shadow={4}
      w="100%"
      maxW="500"
      bgColor="white"
    >
      <VStack space={4} mb={4}>
        <VStack space={2}>
          <HStack space={2}>
            <Text>Day</Text>
            <MaskedInput
              value={addDay}
              onChangeText={setAddDay}
              mask={"xx/xx/xxxx"}
            />
          </HStack>
          <HStack space={2}>
            <Text>Duration</Text>
            <Input value={duration} onChangeText={setDuration} />
          </HStack>
          <Radio.Group
            ml={2}
            size={"sm"}
            name="meal"
            value={meal}
            onChange={setMeal}
          >
            <HStack space={4}>
              <Radio size={"sm"} value="breakfast" my={1}>
                Breakfast
              </Radio>
              <Radio size={"sm"} value="lunch" my={1}>
                Lunch
              </Radio>
              <Radio size={"sm"} value="dinner" my={1}>
                Dinner
              </Radio>
            </HStack>
          </Radio.Group>
        </VStack>
        <Text bold>Search recipes</Text>
        <Input
          size={"sm"}
          placeholder="Add recipe to meal plan"
          w="100%"
          maxW="400"
          onChangeText={doSearch}
        />
        <Radio.Group
          ml={2}
          size={"sm"}
          name="recipe"
          value={searchBy}
          onChange={(value) => setSearchBy(value)}
        >
          <HStack space={4}>
            <Radio size={"sm"} value="recipe" my={1}>
              Recipe name
            </Radio>
            <Radio size={"sm"} value="ingredient" my={1}>
              Recipe ingredient
            </Radio>
          </HStack>
        </Radio.Group>
      </VStack>
      <VStack space={2}>
        {searchResults &&
          searchResults.map((recipe) => (
            <RecipeSearchResult
              key={recipe.id}
              recipe={recipe}
              onPress={(recipe) => onPress(recipe, addDay, duration, meal)}
            />
          ))}
      </VStack>
    </Box>
  );
}
