import { createStackNavigator } from "@react-navigation/stack";
import { Icon, IconButton } from "native-base";
import { Feather } from "@expo/vector-icons";
import { RecipeDetails } from "./RecipeDetails";
import React from "react";
import { RecipeImportWizard } from "./RecipeImportWizard";
import { RecipeListHome } from "./RecipeList";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import { RootTabParamList } from "../../AppCore";
import {
  GetRecipesDocument,
  useInsertRecipeMutation,
} from "../../generated/graphql";

export type RecipeRootStackParamList = {
  RecipeListHome: undefined;
  RecipeDetails: { recipeId?: string; edit?: boolean };
  RecipeImport: undefined;
};

const Stack = createStackNavigator<RecipeRootStackParamList>();

type Props = BottomTabNavigationProp<RootTabParamList, "Recipes">;

export function RecipeListStack({ navigation }: Props) {
  const [insertRecipe, { error }] = useInsertRecipeMutation({
    refetchQueries: [GetRecipesDocument],
  });
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="RecipeListHome"
        component={RecipeListHome}
        options={{
          title: "Recipes",
          headerRight: () => (
            <IconButton
              mr={2}
              borderRadius="sm"
              variant="solid"
              icon={
                <Icon as={Feather} name="plus" size="sm" color="warmGray.50" />
              }
              onPress={() => {
                return insertRecipe({
                  variables: { name: "My New Recipe", servings: 4.0 },
                }).then((recipe) => {
                  const recipeReturn =
                    recipe.data?.insert_recipes?.returning || [];
                  if (recipeReturn?.length > 0) {
                    navigation.navigate("RecipeDetails", {
                      recipeId: recipeReturn[0].id,
                      edit: true,
                    });
                  } else {
                    console.log(`failed to create new recipe!`);
                    console.log(error);
                  }
                });
              }}
            />
          ),
        }}
      />
      <Stack.Screen name="RecipeDetails" component={RecipeDetails} />
      <Stack.Screen
        name="RecipeImport"
        component={RecipeImportWizard}
        options={{ title: "Recipe Import" }}
      />
    </Stack.Navigator>
  );
}
