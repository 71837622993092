import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { MealPlanCreator } from "./MealPlanCreator";
import { MealPlanList } from "./MealPlanList";
import { Icon, IconButton } from "native-base";
import { Feather } from "@expo/vector-icons";
import {
  GetMealPlanDocument,
  useInsertMealPlanMutation,
} from "../../generated/graphql";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import { RootTabParamList } from "../../AppCore";
import { MealPlanCountSelection } from "./MealPlanCountSelection";
import { MealPlanRecipeSelection } from "./MealPlanRecipeSelection";
import dayjs from "dayjs";
import { dayStorageFormat } from "../../utils/constants";
import { FindRecipe } from "./FindRecipe";

export type MealPlanStackParamList = {
  MealPlanList: undefined;
  MealPlanCreator: { mealPlanId: string };
  "Meal Plan Wizard": { mealPlanId: string };
  "Meal Plan Recipe Selection": { mealPlanId: string };
  FindRecipe: { mealPlanId: string };
};

const Stack = createStackNavigator<MealPlanStackParamList>();

export function MealPlanStack({
  navigation,
}: BottomTabNavigationProp<RootTabParamList, "MealPlan">) {
  const [insertMealPlan, { error }] = useInsertMealPlanMutation({
    refetchQueries: [GetMealPlanDocument],
  });
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MealPlanList"
        component={MealPlanList}
        options={{
          title: "Meal Plans",
          headerRight: () => (
            <IconButton
              mr={2}
              borderRadius="sm"
              variant="solid"
              icon={
                <Icon as={Feather} name="plus" size="sm" color="warmGray.50" />
              }
              onPress={() => {
                return insertMealPlan({
                  variables: {
                    name: "This week's meal plan",
                    startsOn: dayjs().format(dayStorageFormat),
                    endsOn: dayjs().add(7, "days").format(dayStorageFormat),
                  },
                }).then((mealPlanResult) => {
                  const mealPlan = mealPlanResult.data?.insert_meal_plans_one;
                  if (mealPlan !== undefined) {
                    navigation.navigate("Meal Plan Wizard", {
                      mealPlanId: mealPlan?.id,
                    });
                  } else {
                    console.log(`failed to create new meal plan!`);
                    console.log(error);
                  }
                });
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="Meal Plan Wizard"
        component={MealPlanCountSelection}
        options={{ title: "Let's Create Your Meal Plan!" }}
      />
      <Stack.Screen
        name="Meal Plan Recipe Selection"
        component={MealPlanRecipeSelection}
        options={{ title: "Let's Pick Your Recipes!" }}
      />
      <Stack.Screen
        name="MealPlanCreator"
        component={MealPlanCreator}
        options={{
          title: "Meal Plan Creator",
        }}
      />
      <Stack.Screen
        name="FindRecipe"
        component={FindRecipe}
        options={{ title: "Search Recipes" }}
      />
    </Stack.Navigator>
  );
}
