import { Input, Spinner, VStack } from "native-base";
import { AutoComplete } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { LazyHook } from "../utils/types";

type SearchOption<Base> = {
  label: string;
  value: string;
} & Base;

type SmartSearchProps<TData, TVariables, OptionBase> = {
  useLazyQuery: LazyHook<TData, TVariables>;
  onSearchTransform: (searchText: string) => TVariables;
  toOptions: (data: TData) => SearchOption<OptionBase>[];
  handleSelect: (selected: OptionBase) => void;
  initialOptionDisplay?: string;
};

export function SmartAutoComplete<TData, TVariables, OptionBase>({
  useLazyQuery,
  onSearchTransform,
  toOptions,
  handleSelect,
  initialOptionDisplay,
}: SmartSearchProps<TData, TVariables, OptionBase>) {
  const [search, { loading, data }] = useLazyQuery();
  const [searchValue, setSearchValue] = useState<string>(
    initialOptionDisplay || ""
  );
  const [options, setOptions] = useState<SearchOption<OptionBase>[]>([]);

  useEffect(() => {
    if (data) {
      setOptions(toOptions(data));
    }
  }, [data]);

  const onSearchRaw = (searchText: string) => {
    setSearchValue(searchText);
    return search({
      variables: onSearchTransform(searchText),
    });
  };

  const onSearch = useCallback(debounce(onSearchRaw, 200), []);

  useEffect(() => {
    if (searchValue) {
      onSearch(searchValue);
    }
  }, [searchValue]);

  return (
    <VStack space={2}>
      <AutoComplete<string, SearchOption<OptionBase>>
        value={searchValue}
        onSearch={setSearchValue}
        options={options}
        onSelect={(v: string, o: SearchOption<OptionBase>) => {
          setSearchValue(o.label);
          handleSelect(o);
        }}
      >
        <Input InputRightElement={loading ? <Spinner /> : undefined} />
      </AutoComplete>
    </VStack>
  );
}
