import React, { useMemo, useState } from "react";
import {
  GetItemsDocument,
  GetRecipeCoreFragment,
  GetRecipeIngredientsWithItemSourcesDocument,
  GetRecipesDocument,
  Item_Sources_Insert_Input,
  RecipeIngredientWithItemSourcesFragment,
  useDeleteItemSourceMutation,
  useDeleteRecipeIngredientMutation,
  useDeleteRecipeMutation,
  useGetFullRecipeQuery,
  useGetRecipeIngredientsWithItemSourcesQuery,
  useInsertManyItemSourcesMutation,
} from "../../generated/graphql";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { RecipeRootStackParamList } from "./RecipeStack";
import { AddRecipeIngredient } from "../../components/ingredients/AddRecipeIngredient";
import { every } from "lodash";
import { SectionList } from "react-native";
import dayjs from "dayjs";
import { isOnList } from "../../utils/recipes";
import { RecipeHeader } from "../../components/recipe/RecipeHeader";
import { RecipeIngredientWrapper } from "../../components/recipe/RecipeIngredientWrapper";
import { PopoverWrapper } from "../../components/popover/PopoverWrapper";
import { getSections } from "./utils";

export function RecipeDetails({
  navigation,
  route: {
    params: { recipeId, edit },
  },
}: StackScreenProps<RecipeRootStackParamList, "RecipeDetails">) {
  const [editMode, setEditMode] = useState(edit || false);

  // array of recipe_ingredient.id that are currently being modified
  // we need this so we can re-render a particular row as loading
  const [editingIds, setEditingIds] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().startOf("day").day(0)
  );
  const endDate = useMemo(() => startDate.add(7, "days"), [startDate]);

  const {
    data: recipeData,
    loading: recipeLoading,
    error: recipeError,
  } = useGetFullRecipeQuery({
    variables: { id: recipeId },
  });

  const { data: ingredients, loading: ingredientsLoading } =
    useGetRecipeIngredientsWithItemSourcesQuery({
      variables: { recipe_id: recipeId },
    });

  const [deleteRecipeIngredientMutation] = useDeleteRecipeIngredientMutation();

  const refetchIngredients = {
    query: GetRecipeIngredientsWithItemSourcesDocument,
    variables: { recipe_id: recipeId },
  };
  const refetchShoppingList = {
    query: GetItemsDocument,
  };

  const [addManyToShoppingList, { loading: addManyLoading }] =
    useInsertManyItemSourcesMutation();

  const [removeFromShoppingList, { loading: removeFromShoppingListLoading }] =
    useDeleteItemSourceMutation();

  const recipe: GetRecipeCoreFragment | undefined | null =
    recipeData?.recipes_by_pk;

  const [deleteRecipe, { loading: deleteRecipeLoading }] =
    useDeleteRecipeMutation({
      variables: { id: recipeId },
      refetchQueries: [GetRecipesDocument],
    });

  const noIngredients =
    !ingredients || (ingredients && ingredients.recipe_ingredients.length <= 0);

  const sections = getSections(ingredients?.recipe_ingredients);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: recipe?.name,
      headerRight: () => (
        <IconButton
          mr={2}
          borderRadius="sm"
          variant="ghost"
          icon={
            <Icon
              as={FontAwesome}
              name={editMode ? "check" : "pencil"}
              size="sm"
            />
          }
          onPress={() => (editMode ? setEditMode(false) : setEditMode(true))}
        />
      ),
    });
  }, [navigation, recipeId, recipe, editMode]);

  function handleDeleteRecipeIngredient(
    recipeIngredient: RecipeIngredientWithItemSourcesFragment
  ) {
    return deleteRecipeIngredientMutation({
      variables: { id: recipeIngredient.id },
      refetchQueries: [refetchIngredients],
    });
  }

  function handleAddToShoppingList(
    recipeIngredient: RecipeIngredientWithItemSourcesFragment
  ): Promise<unknown> {
    setEditingIds([recipeIngredient.id]);

    const alreadyExists = isOnList(startDate, endDate, recipeIngredient);

    if (alreadyExists) {
      return Promise.all(
        recipeIngredient.item_sources.map((source) =>
          removeFromShoppingList({
            variables: { id: source.id },
            refetchQueries: [refetchIngredients, refetchShoppingList],
          })
        )
      ).then(() => setEditingIds([]));
    } else {
      const objects: Item_Sources_Insert_Input[] = [
        {
          recipe_ingredient_id: recipeIngredient.id,
          desired_at: startDate,
        },
      ];

      return addManyToShoppingList({
        variables: { objects },
        refetchQueries: [refetchIngredients, refetchShoppingList],
      }).then(() => setEditingIds([]));
    }
  }

  function handleAddAllToShoppingList(
    ingredients?: RecipeIngredientWithItemSourcesFragment[]
  ) {
    if (!ingredients) {
      return Promise.resolve();
    }

    const objects: Item_Sources_Insert_Input[] = ingredients
      .filter((x) => !isOnList(startDate, endDate, x))
      .map((x) => ({ recipe_ingredient_id: x.id, desired_at: startDate }));

    setEditingIds(objects.map((o) => o.recipe_ingredient_id));

    if (objects.length > 0) {
      return addManyToShoppingList({
        variables: { objects },
        refetchQueries: [refetchIngredients],
      }).then(() => setEditingIds([]));
    }

    return Promise.resolve().then(() => setEditingIds([]));
  }

  const addOrRemoveLoading = addManyLoading || removeFromShoppingListLoading;
  const showAddAll = !every(
    ingredients?.recipe_ingredients.map((i) => isOnList(startDate, endDate, i))
  );

  if (!recipe) {
    return <Box />;
  }

  if (recipeLoading) {
    return (
      <Box>
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (recipeError) {
    return (
      <Box>
        <Text>Error loading recipe</Text>
      </Box>
    );
  }

  return (
    <ScrollView>
      <Box flex={1}>
        <RecipeHeader recipe={recipe} editMode={editMode} />
        {/*{!!recipe?.note && (*/}
        {/*  <RecipeNoteSection recipeId={recipeId!} recipeNote={recipe.note} />*/}
        {/*)}*/}
        <Box>
          <Heading size="md" px="4" mt="4">
            Ingredients
          </Heading>
          <Box p={5} rounded="xl" shadow={4} bgColor="white" mt="2" mx="4">
            <VStack space={2} bg="white" mt="2" px="2" py="2">
              {!ingredientsLoading && !noIngredients && (
                <HStack alignItems="center" space="2">
                  {showAddAll && (
                    <Button
                      onPress={() =>
                        handleAddAllToShoppingList(
                          ingredients?.recipe_ingredients
                        )
                      }
                    >
                      {/*TODO update mutation so these get added as ad hoc recipe to shopping list without a date? */}
                      Add All to shopping list
                    </Button>
                  )}
                  <IconButton
                    variant="ghost"
                    _icon={{
                      as: AntDesign,
                      name: "caretleft",
                    }}
                    onPress={() => setStartDate(startDate.subtract(7, "days"))}
                  />
                  <Text>
                    {startDate.format("MMM D")} to {endDate.format("MMM D")}
                  </Text>
                  <IconButton
                    variant="ghost"
                    _icon={{
                      as: AntDesign,
                      name: "caretright",
                    }}
                    onPress={() => setStartDate(startDate.add(7, "days"))}
                  />
                </HStack>
              )}
              {ingredientsLoading && <Text>Loading...</Text>}
              {!ingredientsLoading && (
                <VStack space="xs" zIndex={5}>
                  {noIngredients && (
                    <Heading size="xs">No ingredients?</Heading>
                  )}
                  {(noIngredients || editMode) && (
                    <AddRecipeIngredient recipeId={recipe.id} />
                  )}
                </VStack>
              )}
              <SectionList
                sections={sections}
                extraData={editingIds}
                renderItem={({ item, index }) => (
                  <RecipeIngredientWrapper
                    key={index}
                    item={item}
                    checked={isOnList(startDate, endDate, item)}
                    loading={addOrRemoveLoading && editingIds.includes(item.id)}
                    editMode={editMode}
                    onPressDelete={handleDeleteRecipeIngredient}
                    onPressCheckbox={handleAddToShoppingList}
                  />
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Center bg="primary.500" mt="4" mb="2">
                    <Heading fontSize="sm" mt="1" pb="1" color="warmGray.50">
                      {title}
                    </Heading>
                  </Center>
                )}
              />
            </VStack>
          </Box>
        </Box>
        {editMode && (
          <VStack mt={8} alignItems="center">
            <PopoverWrapper
              popoverTrigger={(popoverProps) => (
                <Button colorScheme="secondary" {...popoverProps}>
                  Delete
                </Button>
              )}
              headerText={"Are you sure?"}
            >
              <Text>Continue deleting this recipe?</Text>
              <Button
                disabled={deleteRecipeLoading}
                colorScheme="secondary"
                onPress={() => {
                  deleteRecipe().then(() => navigation.pop());
                }}
              >
                Yes, Delete
              </Button>
            </PopoverWrapper>
          </VStack>
        )}
      </Box>
    </ScrollView>
  );
}
