import React from "react";
import { Button } from "native-base";
import { useSignOut } from "@nhost/react";

const LogoutButton = () => {
  const { signOut } = useSignOut();

  return (
    <Button
      mt="8"
      onPress={() => signOut({ returnTo: window.location.origin })}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
