import React, { useMemo } from "react";
import { Actionsheet, Box, Heading, Icon, ScrollView, Text } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  displayItemSourceDetails,
  ShoppingListItemWithSources,
} from "../../utils/conversions";

type ShoppingListItemActionSheetProps = {
  item: ShoppingListItemWithSources;
  isOpen: boolean;
  onClose: () => void;
};

export function ShoppingListItemActionSheet({
  item,
  isOpen,
  onClose,
}: ShoppingListItemActionSheetProps) {
  const navigation = useNavigation();

  function goToPage(pageName: string) {
    onClose();
    navigation.navigate(pageName, {
      itemId: item.ingredient_id,
      currentCategory: item.categoryName,
      itemName: item.name,
    });
  }

  const recipeDetails = useMemo(() => displayItemSourceDetails(item), [item]);

  return (
    <>
      <Actionsheet isOpen={isOpen} onClose={onClose} size="full">
        <Actionsheet.Content>
          <Box w="100%" px={4} justifyContent="center">
            <Heading
              size="md"
              ml="-1"
              color="gray.500"
              _dark={{
                color: "gray.300",
              }}
            >
              {item.name}
            </Heading>
            <Text fontWeight="400">{recipeDetails.join("\n")}</Text>
          </Box>
          <ScrollView w="100%">
            <Actionsheet.Item
              startIcon={
                <Icon
                  as={MaterialIcons}
                  name="category"
                  color="trueGray.400"
                  mr="1"
                  size="6"
                />
              }
              onPress={() => goToPage("ItemCategorySelection")}
            >
              Category
            </Actionsheet.Item>
            <Actionsheet.Item
              startIcon={
                <Icon
                  as={MaterialIcons}
                  name="store"
                  color="trueGray.400"
                  mr="1"
                  size="6"
                />
              }
              onPress={() => goToPage("ItemStoreSelection")}
            >
              Stores
            </Actionsheet.Item>
            <Actionsheet.Item
              startIcon={
                <Icon
                  as={MaterialIcons}
                  color="trueGray.400"
                  mr="1"
                  size="6"
                  name="favorite"
                />
              }
            >
              Favorite
            </Actionsheet.Item>
            <Actionsheet.Item
              startIcon={
                <Icon
                  as={MaterialIcons}
                  color="trueGray.400"
                  mr="1"
                  size="6"
                  name="delete"
                />
              }
            >
              Delete
            </Actionsheet.Item>
          </ScrollView>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
}
