import { groupBy } from "lodash";
import { SectionListData } from "react-native";
import { RecipeIngredientWithItemSourcesFragment } from "../../generated/graphql";

export function getSections(
  recipe_ingredients?: RecipeIngredientWithItemSourcesFragment[],
  groupByInput: string = "ingredient"
): ReadonlyArray<SectionListData<RecipeIngredientWithItemSourcesFragment>> {
  const ingredientsByCategory = groupBy(
    recipe_ingredients,
    (i) => i.ingredient?.category?.name || "Other"
  );
  // TODO add a way to group by recipe step vs ingredient category
  return Object.entries(ingredientsByCategory).map(([key, value]) => ({
    title: key,
    data: value,
  }));
}
