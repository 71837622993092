import { Box, Heading, HStack, Icon, Link, Text, VStack } from "native-base";
import { StarRating } from "./StarRating";
import { SmartInput } from "../SmartInput";
import {
  GetRecipeCoreFragment,
  useUpdateRecipeNameMutation,
  useUpdateRecipeNotesMutation,
  useUpdateRecipeServingsMutation,
  useUpdateRecipeSourceMutation,
} from "../../generated/graphql";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { RecipeCookbookInput } from "./RecipeCookbookInput";

type RecipeHeaderProps = {
  recipe: GetRecipeCoreFragment;
  editMode: boolean;
};

export function RecipeHeader({ recipe, editMode }: RecipeHeaderProps) {
  return (
    <Box p={5} rounded="xl" shadow={4} bgColor="white" mt="6" mx="4">
      <VStack>
        <StarRating rating={recipe?.rating} recipeId={recipe.id} size="sm" />
        {editMode ? (
          <SmartInput
            initialValue={recipe.name}
            propName={"name"}
            useMutation={useUpdateRecipeNameMutation}
            recipeId={recipe.id}
            size="2xl"
            mb="2"
          />
        ) : (
          <Heading mb="2">{recipe?.name}</Heading>
        )}

        <VStack space="sm">
          {(recipe.source || editMode) && (
            <HStack space="xs" alignItems="center">
              <Icon
                as={MaterialCommunityIcons}
                name="book"
                color="warmGray.400"
                size="sm"
                _dark={{
                  color: "warmGray.50",
                }}
              />
              {editMode ? (
                <SmartInput
                  initialValue={recipe.source || undefined}
                  propName={"source"}
                  useMutation={useUpdateRecipeSourceMutation}
                  recipeId={recipe.id}
                />
              ) : (
                <Link
                  href={recipe.source}
                  isExternal
                  _text={{
                    color: "blue.400",
                  }}
                >
                  {recipe.source}
                </Link>
              )}
            </HStack>
          )}
          {(recipe.cookbook || editMode) && (
            <HStack space="xs" alignItems="center">
              <Icon
                as={MaterialCommunityIcons}
                name="book"
                color="warmGray.400"
                size="sm"
                _dark={{
                  color: "warmGray.50",
                }}
              />
              {editMode ? (
                <RecipeCookbookInput recipeId={recipe.id} />
              ) : (
                <>
                  <Text>{recipe.cookbook?.name}</Text>
                </>
              )}
            </HStack>
          )}
          <HStack space="xs" alignItems="center">
            <Icon
              as={MaterialCommunityIcons}
              name="silverware-fork-knife"
              color="warmGray.400"
              size="sm"
              _dark={{
                color: "warmGray.50",
              }}
            />
            {editMode ? (
              <>
                <SmartInput
                  initialValue={recipe.servings}
                  propName={"servings"}
                  useMutation={useUpdateRecipeServingsMutation}
                  recipeId={recipe.id}
                />
                <Text> Servings</Text>
              </>
            ) : (
              <Text>{recipe.servings} Servings</Text>
            )}
          </HStack>
          {(recipe.notes || editMode) && (
            <HStack space="xs" alignItems="center">
              <Icon
                as={MaterialCommunityIcons}
                name="note-outline"
                color="warmGray.400"
                size="sm"
                _dark={{
                  color: "warmGray.50",
                }}
              />
              {editMode ? (
                <SmartInput
                  initialValue={recipe.notes || undefined}
                  propName={"notes"}
                  useMutation={useUpdateRecipeNotesMutation}
                  recipeId={recipe.id}
                />
              ) : (
                <Text>{recipe.notes}</Text>
              )}
            </HStack>
          )}
        </VStack>
        {/*{displayTags && (*/}
        {/*  <HStack space="xs" mt="4" alignItems="center">*/}
        {/*    {recipe?.recipe_tags.map((t) => (*/}
        {/*      <Tag>{t.tag.name}</Tag>*/}
        {/*    ))}*/}
        {/*    <Button*/}
        {/*      variant="ghost"*/}
        {/*      size="xs"*/}
        {/*      leftIcon={<Icon as={AntDesign} name="plussquareo" size="sm" />}*/}
        {/*    />*/}
        {/*  </HStack>*/}
        {/*)}*/}
      </VStack>
    </Box>
  );
}
