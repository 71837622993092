import { Box, Button, HStack, Input } from "native-base";
import React, { useState } from "react";
import { MeasureSelect } from "./MeasureSelect";
import { IngredientSearch } from "./IngredientSearch";
import {
  GetRecipeIngredientsWithItemSourcesDocument,
  IngredientSearchFragment,
  useInsertRecipeIngredientMutation,
} from "../../generated/graphql";
import { fractionConversion } from "../../utils/conversions";

type Props = {
  // needed to know which recipe this ingredient gets added to
  // as well as to refetch all ingredients after a mutation
  recipeId: string;
};

export function AddRecipeIngredient({ recipeId }: Props) {
  const refetchIngredients = {
    query: GetRecipeIngredientsWithItemSourcesDocument,
    variables: { recipe_id: recipeId },
  };
  const [insertRecipeIngredient] = useInsertRecipeIngredientMutation();
  const [amount, setAmount] = useState<string>("");
  const [measure, setMeasure] = useState<string | undefined>();
  const [ingredient, setIngredient] = useState<
    IngredientSearchFragment | undefined
  >();
  const [preparation, setPreparation] = useState<string>("");

  function handleAdd() {
    console.log(`${amount} ${measure} ${ingredient?.name}`);
    return insertRecipeIngredient({
      variables: {
        amount: fractionConversion(amount),
        measure,
        ingredient_id: ingredient?.id,
        recipe_id: recipeId,
        preparation,
      },
      refetchQueries: [refetchIngredients],
    }).then(() => {
      setAmount("");
    });
  }

  return (
    <HStack alignItems="center" space={2} flex={1} flexWrap>
      <Box flex={3}>
        <IngredientSearch handleSelect={(i) => setIngredient(i)} />
      </Box>
      <Input
        placeholder="Amount"
        maxWidth="100px"
        flex={1}
        value={amount}
        onChangeText={(x) => setAmount(x)}
      />
      <Box flex={1}>
        <MeasureSelect
          onSelect={(m) => {
            return Promise.resolve(setMeasure(m));
          }}
        />
      </Box>
      <Input
        placeholder="chopped, diced, etc."
        value={preparation}
        onChangeText={setPreparation}
      />
      <Button size="sm" onPress={handleAdd}>
        Add
      </Button>
    </HStack>
  );
}
