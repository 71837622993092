import {
  Box,
  Button,
  HStack,
  Input,
  Radio,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import { RecipeSearchResult } from "./RecipeSearchResult";
import React, { useEffect, useState } from "react";
import {
  GetRecipeCoreWithIngredientsFragment,
  useSearchRecipesByIngredientLazyQuery,
  useSearchRecipesLazyQuery,
} from "../../generated/graphql";
import { QuickAddRecipeCard } from "../recipeCard/QuickAddRecipeCard";

type RecipeSearchSidebarProps = {
  onPress: (recipe: GetRecipeCoreWithIngredientsFragment) => Promise<unknown>;
};

export function RecipeOnlySearchSidebar({ onPress }: RecipeSearchSidebarProps) {
  const [quickAdd, setQuickAdd] = useState(false);
  const [searchBy, setSearchBy] = useState<string>("recipe");
  const [searchResults, setSearchResults] = useState<
    GetRecipeCoreWithIngredientsFragment[] | undefined
  >([]);

  const [searchRecipes, { data: byRecipeData }] = useSearchRecipesLazyQuery();
  const [searchRecipesByIngredient, { data: byIngredientData }] =
    useSearchRecipesByIngredientLazyQuery();

  useEffect(() => {
    if (searchBy === "recipe") {
      setSearchResults(byRecipeData?.recipes);
    } else if (searchBy === "ingredient") {
      setSearchResults(byIngredientData?.recipes);
    } else {
      setSearchResults([]);
    }
  }, [byRecipeData, byIngredientData, searchBy]);

  const doSearch = (text: string) => {
    const searchFunction =
      searchBy === "recipe" ? searchRecipes : searchRecipesByIngredient;
    return searchFunction({ variables: { search: `%${text}%` } });
  };
  return (
    <Box flex={1} rounded="xl" p={5} w="100%" maxW="500" bgColor="white">
      <VStack space={4} mb={4}>
        <Input
          size={"sm"}
          placeholder="Search Recipes"
          w="100%"
          maxW="400"
          onChangeText={doSearch}
        />
        <HStack justifyContent="space-between">
          <Button.Group
            isAttached
            mx={{
              base: "auto",
              md: 0,
            }}
            variant="outline"
            size="sm"
          >
            <Button
              onPress={() => setSearchBy("recipe")}
              variant={searchBy === "recipe" ? "solid" : "outline"}
            >
              Name
            </Button>
            <Button
              onPress={() => setSearchBy("ingredient")}
              variant={searchBy === "ingredient" ? "solid" : "outline"}
            >
              Ingredient
            </Button>
            <Button
              onPress={() => setSearchBy("category")}
              variant={searchBy === "category" ? "solid" : "outline"}
            >
              Category
            </Button>
          </Button.Group>
          <Button size="sm" onPress={() => setQuickAdd(!quickAdd)}>
            {quickAdd ? "Close Quick Add" : "Quick Add"}
          </Button>
        </HStack>
        {quickAdd && <QuickAddRecipeCard onSave={onPress} />}
      </VStack>

      <ScrollView>
        <VStack space={2}>
          {searchResults &&
            searchResults.map((recipe) => (
              <RecipeSearchResult
                key={recipe.id}
                recipe={recipe}
                onPress={onPress}
              />
            ))}
        </VStack>
      </ScrollView>
    </Box>
  );
}
