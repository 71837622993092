import { HStack, Icon, IconButton, Input } from "native-base";
import { Feather } from "@expo/vector-icons";
import React, { useState } from "react";

type AddInputProps = {
  onSubmit: (inputText: string) => Promise<unknown>;
};

export function AddInput({ onSubmit }: AddInputProps) {
  const [newItemName, setNewItemName] = useState("");

  return (
    <HStack space={2} margin={5}>
      <Input
        flex={1}
        onChangeText={(v) => setNewItemName(v)}
        value={newItemName}
        placeholder="Add"
      />
      <IconButton
        borderRadius="sm"
        variant="solid"
        icon={<Icon as={Feather} name="plus" size="sm" color="warmGray.50" />}
        onPress={() =>
          Promise.resolve()
            .then(onSubmit(newItemName))
            .then(() => setNewItemName(""))
        }
      />
    </HStack>
  );
}
