import React from "react";
import {
  Box,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Center,
  HStack,
  Icon,
  Avatar,
  VStack,
} from "native-base";
import LogoutButton from "./LogoutButton";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useAuthenticationStatus, useUserData } from "@nhost/react";
import { Staples } from "../settings/Staples";

const ExampleProfile = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const user = useUserData();

  return isAuthenticated ? (
    <Center w="100%" mt="8" px="4">
      <VStack space={4} w="100%" maxW="500">
        <Box p={5} rounded="xl" shadow={4} bgColor="white">
          <Stack space={6}>
            <Avatar
              size={"xl"}
              source={{
                uri: user?.avatarUrl,
              }}
            />
            <Stack space={3}>
              <Heading
                size="lg"
                color={useColorModeValue("blueGray.700", "blueGray.100")}
              >
                {user?.displayName}
              </Heading>
            </Stack>
            <HStack
              justifyContent="space-between"
              alignItems="flex-end"
              flexShrink={1}
            >
              <Stack space={3}>
                <HStack space={3} alignItems="center" flexShrink={1}>
                  <Icon
                    name="grid"
                    as={MaterialCommunityIcons}
                    color="blueGray.700"
                  />
                  <Text
                    flexShrink={1}
                    fontWeight="medium"
                    color={useColorModeValue("blueGray.500", "blueGray.200")}
                  >
                    Email: {user?.email}
                  </Text>
                </HStack>
              </Stack>
            </HStack>
          </Stack>
        </Box>
        <Box p={5} rounded="xl" shadow={4} bgColor="white">
          <Staples />
        </Box>
        <LogoutButton />
      </VStack>
    </Center>
  ) : (
    <>
      <Text>TODO: let users login</Text>
    </>
  );
};

export default ExampleProfile;
