import { Box, Pressable } from "native-base";
import { ResponsiveValue } from "native-base/lib/typescript/components/types";
import { ReactNode } from "react";

type StandardBoxProps = {
  children: ReactNode;
  width?: ResponsiveValue<string | number>;
  gap?: boolean;
};

export function StandardBox({
  children,
  width,
  gap = false,
}: StandardBoxProps) {
  return (
    <Pressable
      minWidth={250}
      width={width}
      p={4}
      rounded="xl"
      backgroundColor={gap ? undefined : "white"}
      borderColor={gap ? undefined : "black"}
      borderWidth={gap ? undefined : 1}
    >
      <Box>{children}</Box>
    </Pressable>
  );
}
