import React, { useState } from "react";
import { useSignUpEmailPassword } from "@nhost/react";
import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Link,
  VStack,
} from "native-base";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { UnauthenticatedParamList } from "../../AppCore";

export function SignUp({
  navigation,
}: StackScreenProps<UnauthenticatedParamList, "signup">) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signUpEmailPassword, isSuccess } = useSignUpEmailPassword();

  const handleOnSubmit = () => {
    signUpEmailPassword(email, password, {
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: {
        firstName,
        lastName,
      },
    });
  };

  console.log(isSuccess);
  if (isSuccess) {
    // TODO navigate away to home
  }

  return (
    <Center w="100%">
      <Box safeArea p="2" w="90%" maxW="290" py="8">
        <Heading
          size="lg"
          color="coolGray.800"
          _dark={{
            color: "warmGray.50",
          }}
          fontWeight="semibold"
        >
          Welcome
        </Heading>
        <Heading
          mt="1"
          color="coolGray.600"
          _dark={{
            color: "warmGray.200",
          }}
          fontWeight="medium"
          size="xs"
        >
          Sign up to continue!
        </Heading>
        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>First Name</FormControl.Label>
            <Input onChangeText={setFirstName} />
          </FormControl>
          <FormControl>
            <FormControl.Label>Last Name</FormControl.Label>
            <Input onChangeText={setLastName} />
          </FormControl>
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input onChangeText={setEmail} />
          </FormControl>
          <FormControl>
            <FormControl.Label>Password</FormControl.Label>
            <Input type="password" onChangeText={setPassword} />
          </FormControl>
          {/*<FormControl>*/}
          {/*  <FormControl.Label>Confirm Password</FormControl.Label>*/}
          {/*  <Input type="password" />*/}
          {/*</FormControl>*/}
          <Button mt="2" colorScheme="indigo" onPress={() => handleOnSubmit()}>
            Sign up
          </Button>
          <Button
            variant="link"
            _text={{
              color: "indigo.500",
              fontWeight: "medium",
              fontSize: "sm",
            }}
            onPress={() => navigation.navigate("login")}
          >
            Back to login
          </Button>
        </VStack>
      </Box>
    </Center>
  );
}
