import { Box, Button, HStack, Input, Text, VStack } from "native-base";
import {
  GetRecipeCoreWithIngredientsFragment,
  GetRecipesDocument,
  useInsertRecipeMutation,
} from "../../generated/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { Counter } from "../mealplan/Counter";

type QuickAddRecipeCardProps = {
  onSave: (recipe: GetRecipeCoreWithIngredientsFragment) => Promise<unknown>;
};

export function QuickAddRecipeCard({ onSave }: QuickAddRecipeCardProps) {
  const [servings, setServings] = useState(4);
  const [name, setName] = useState("");
  const [insertRecipe, { error, loading, data }] = useInsertRecipeMutation({
    refetchQueries: [GetRecipesDocument],
  });

  const invalid = useMemo(() => name === "", [name]);

  useEffect(() => {
    const recipeReturn = data?.insert_recipes?.returning || [];
    if (recipeReturn.length > 0) {
      onSave(recipeReturn[0]);
      setServings(4);
      setName("");
    }
  }, [data]);

  const handleRecipeAdd = () => {
    insertRecipe({ variables: { name, servings } });
  };

  return (
    <Box pl={4} p={4} bg={"muted.100"} rounded={"xl"}>
      <VStack space={2}>
        <Input
          value={name}
          onSubmitEditing={handleRecipeAdd}
          onChangeText={setName}
          bg="white"
        />
        <HStack justifyContent="space-between" alignItems="center">
          <Counter increment={1} value={servings} setValue={setServings} />
          <Button
            size="xs"
            onPress={handleRecipeAdd}
            isLoading={loading}
            isDisabled={invalid}
          >
            Add
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
