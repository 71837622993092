import { Box, HStack, Input, Spinner } from "native-base";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MeasureSelect } from "./MeasureSelect";
import { IngredientSearch } from "./IngredientSearch";
import {
  IngredientSearchFragment,
  Recipe_Ingredients_Set_Input,
  useUpdateRecipeIngredientMutation,
} from "../../generated/graphql";
import { fractionConversion } from "../../utils/conversions";
import { debounce } from "lodash";
import { filterEmptyValues } from "../../utils/general";

type Props = {
  recipeIngredientId: string;
  initialAmount?: string;
  initialMeasure?: string;
  initialIngredient?: IngredientSearchFragment;
  initialPreparation?: string;
};

export function EditRecipeIngredient({
  recipeIngredientId,
  initialAmount,
  initialMeasure,
  initialIngredient,
  initialPreparation,
}: Props) {
  const initialRender = useRef(true);
  const [updateRecipeIngredient, { loading: updateLoading }] =
    useUpdateRecipeIngredientMutation();
  const [amount, setAmount] = useState<string>(initialAmount || "");
  const [measure, setMeasure] = useState<string | undefined>(initialMeasure);
  const [ingredient, setIngredient] = useState<
    IngredientSearchFragment | undefined
  >(initialIngredient);
  const [preparation, setPreparation] = useState<string>(
    initialPreparation || ""
  );

  function handleUpdate(update: {
    updatedAmount?: string;
    updatedMeasure?: string;
    updatedIngredient?: IngredientSearchFragment;
    updatedPreparation?: string;
  }) {
    const updateBody = {
      amount: update.updatedAmount && fractionConversion(update.updatedAmount),
      measure: update.updatedMeasure,
      preparation: update.updatedPreparation,
      ingredient_id: update.updatedIngredient?.id,
    };

    const filteredUpdateBody: Recipe_Ingredients_Set_Input =
      filterEmptyValues(updateBody);

    return updateRecipeIngredient({
      variables: {
        id: recipeIngredientId,
        updateBody: filteredUpdateBody,
      },
    });
  }

  // measure handlers
  function handleUpdateMeasure(updatedMeasure: string) {
    return handleUpdate({ updatedMeasure });
  }

  const debounceUpdateMeasure = useCallback(
    debounce(handleUpdateMeasure, 500),
    []
  );

  useEffect(() => {
    if (!initialRender.current) {
      debounceUpdateMeasure(measure);
    }
  }, [measure]);

  // amount handlers
  function handleUpdateAmount(updatedAmount: string) {
    return handleUpdate({ updatedAmount });
  }

  const debounceUpdateAmount = useCallback(
    debounce(handleUpdateAmount, 500),
    []
  );

  useEffect(() => {
    if (!initialRender.current) {
      // TODO if value is empty show error state on form
      debounceUpdateAmount(amount);
    }
  }, [amount]);

  // preparation handlers
  function handleUpdatePreparation(updatedPreparation: string) {
    return handleUpdate({ updatedPreparation });
  }

  const debounceUpdatePreparation = useCallback(
    debounce(handleUpdatePreparation, 500),
    []
  );

  useEffect(() => {
    if (!initialRender.current) {
      debounceUpdatePreparation(preparation);
    }
  }, [preparation]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  });

  return (
    <HStack alignItems="center" space={2} flex={1} flexWrap>
      <Box flex={3}>
        <IngredientSearch
          initialIngredient={ingredient}
          handleSelect={(i) => {
            setIngredient(i);
            return handleUpdate({ updatedIngredient: i });
          }}
        />
      </Box>
      <Input
        placeholder="Amount"
        maxWidth="100px"
        flex={1}
        value={amount}
        onChangeText={setAmount}
        InputRightElement={updateLoading ? <Spinner /> : undefined}
      />
      <Box flex={1}>
        <MeasureSelect onSelect={setMeasure} initialMeasure={initialMeasure} />
      </Box>
      <Input
        placeholder="chopped, diced, etc."
        value={preparation}
        onChangeText={setPreparation}
        InputRightElement={updateLoading ? <Spinner /> : undefined}
      />
    </HStack>
  );
}
