import {
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Spinner,
  Text,
  VStack,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import { GetRecipeCoreWithIngredientsFragment } from "../../generated/graphql";

type RecipeSearchResultProps = {
  recipe: GetRecipeCoreWithIngredientsFragment;
  onPress: (recipe: GetRecipeCoreWithIngredientsFragment) => Promise<unknown>;
};

export function RecipeSearchResult({
  recipe,
  onPress,
}: RecipeSearchResultProps) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Pressable
      onPress={() => {
        setIsLoading(true);
        return onPress(recipe).then(() => setIsLoading(false));
      }}
      disabled={isLoading}
    >
      <Box pl={4} p={4} bg={"muted.100"} rounded={"xl"}>
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <VStack>
            <Heading size="sm">{recipe.name}</Heading>
            <Text>{recipe.servings} servings</Text>
          </VStack>
          {!isLoading && (
            <IconButton
              icon={<Icon size={"md"} as={Ionicons} name={"add"} />}
            />
          )}
          {isLoading && <Spinner />}
        </HStack>
      </Box>
    </Pressable>
  );
}
