import { CheckIcon, Select } from "native-base";
import React, { useState } from "react";

const MeasureOptions = {
  EMPTY: "none",
  PINCH: "pinch",
  TBSP: "tbsp",
  TSP: "tsp",
  GRAM: "g",
  OUNCE: "oz",
  CUP: "cup",
  POUND: "lb",
  GALLON: "gallon",
  QUART: "quart",
  PINT: "pint",
  CLOVE: "cloves",
  FILLET: "fillet",
  BUNCH: "bunch",
  CAN: "can",
};

type MeasureOptionTypes = keyof typeof MeasureOptions;

type Props = {
  onSelect?: (selected?: MeasureOptionTypes) => Promise<unknown>;
  initialMeasure?: string;
};

export function MeasureSelect({ onSelect, initialMeasure }: Props) {
  const [selected, setSelected] = useState<MeasureOptionTypes | undefined>(
    initialMeasure
  );

  function handleSelect(measureOption: MeasureOptionTypes) {
    setSelected(measureOption);
    if (onSelect) {
      if (measureOption === MeasureOptions.EMPTY) {
        return onSelect(undefined);
      }
      return onSelect(measureOption);
    }
    return Promise.resolve();
  }

  return (
    <Select
      selectedValue={selected}
      minWidth="100"
      accessibilityLabel="Choose Measurement"
      placeholder="Measurement"
      _selectedItem={{
        bg: "teal.600",
        endIcon: <CheckIcon size="5" />,
      }}
      onValueChange={(itemValue) =>
        handleSelect(itemValue as MeasureOptionTypes)
      }
    >
      {Object.entries(MeasureOptions).map(([key, value]) => (
        <Select.Item key={key} label={value} value={value} />
      ))}
    </Select>
  );
}
