import { Box, HStack, IconButton, Text } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import React from "react";

type CounterProps = {
  value: number;
  setValue: (updatedValue: number) => void;
  increment: number; // size that will be changed on button press
  loading?: boolean;
  min?: number;
  max?: number;
};

export function Counter({
  value,
  setValue,
  increment,
  loading,
  max,
  min = 0,
}: CounterProps) {
  const decrementDisabled = loading || value === min;
  const incrementDisabled = loading || value === max;
  return (
    <Box>
      <HStack alignItems="center" space={4}>
        <IconButton
          variant="solid"
          size="xs"
          _icon={{
            as: Ionicons,
            name: "remove",
          }}
          isDisabled={decrementDisabled}
          onPress={() => !decrementDisabled && setValue(value - increment)}
        />
        <Text>{value}</Text>
        <IconButton
          variant="solid"
          size="xs"
          _icon={{
            as: Ionicons,
            name: "add",
          }}
          isDisabled={incrementDisabled}
          onPress={() => !incrementDisabled && setValue(value + increment)}
        />
      </HStack>
    </Box>
  );
}
