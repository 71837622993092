import { extendTheme } from "native-base";

export const theme = extendTheme({
  colors: {
    secondary: {
      50: "#F68EA3",
      100: "#F47B93",
      200: "#F36884",
      300: "#F15574",
      400: "#F04265",
      500: "#ED254E",
      600: "#ED1D46",
      700: "#E2123C",
      800: "#D01137",
      900: "#BD0F32",
    },
    tertiary: {
      50: "#a3a9b1",
      100: "#9098a1",
      200: "#7e8791",
      300: "#6b7581",
      400: "#596472",
      500: "#465362",
      600: "#3f4b58",
      700: "#38424e",
      800: "#313a45",
      900: "#2a323b",
    },
    primary: {
      50: "#edf3f2",
      100: "#dae7e5",
      200: "#c8dbd7",
      300: "#b5cfca",
      400: "#a3c3bd",
      500: "#90b6b0",
      600: "#7eaaa3",
      700: "#6b9e95",
      800: "#599288",
      900: "#46867b",
    },
  },
});
