import { Checkbox, HStack, Link, Spinner, Text } from "native-base";
import { displayFraction } from "../../utils/conversions";
import React from "react";
import { RecipeIngredientWithItemSourcesFragment } from "../../generated/graphql";
import { useNavigation } from "@react-navigation/native";

type Props = {
  item: RecipeIngredientWithItemSourcesFragment;
  checked: boolean;
  loading: boolean;
  onPressCheckbox: (item: RecipeIngredientWithItemSourcesFragment) => void;
};

export function RecipeIngredientRow({
  item,
  checked,
  loading,
  onPressCheckbox,
}: Props) {
  const navigation = useNavigation();
  //  TODO if there's metadata linked to another recipe_id
  //   then we need to notify the user and give them a way
  //   to specify make vs buy
  const metadata = item.metadata || {};
  const childRecipeId = metadata.recipe_id;
  const displayText = item.ingredient?.name || item.raw_text;
  return (
    <HStack w="100%" space="xs" alignItems="center" key={item.id} pb="1">
      {loading && (
        <Spinner accessibilityLabel="Loading ingredient for recipe" />
      )}
      {!loading && (
        <Checkbox
          isChecked={checked}
          onChange={() => onPressCheckbox(item)}
          value={displayText}
          accessibilityRole="checkbox"
        />
      )}
      <Text _light={{ color: "gray.400" }}>
        {displayFraction(item.amount)} {item.measure} {item.preparation}
      </Text>
      <Text>{displayText}</Text>
      {!!childRecipeId && (
        <Link
          _text={{
            color: "blue.400",
          }}
          onPress={() =>
            navigation.navigate("RecipeDetails", { recipeId: childRecipeId })
          }
        >
          (from recipe)
        </Link>
      )}
    </HStack>
  );
}
