import { Box, HStack, Icon, IconButton, Text } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  ColorType,
  ResponsiveValue,
} from "native-base/lib/typescript/components/types";
import {
  GetItemsForRangeDocument,
  GetMealPlanWithRecipesDocument,
  MealPlanRecipeCoreFragment,
  useDeleteMealPlanRecipeMutation,
} from "../../generated/graphql";

type MealItemProps = {
  mealPlanRecipe: MealPlanRecipeCoreFragment;
  backgroundColor?: ResponsiveValue<ColorType>;
};

export function MealItem({ backgroundColor, mealPlanRecipe }: MealItemProps) {
  const [deleteMealPlanRecipe, { loading }] = useDeleteMealPlanRecipeMutation({
    refetchQueries: [GetMealPlanWithRecipesDocument, GetItemsForRangeDocument],
  });
  return (
    <Box p={1} pl={4} rounded="xl" backgroundColor={backgroundColor}>
      <HStack alignItems="center" justifyContent="space-between">
        <Text fontSize="xs">{mealPlanRecipe.recipe.name}</Text>
        <IconButton
          size="sm"
          icon={<Icon as={Ionicons} name={"md-close-outline"} />}
          disabled={loading}
          onPress={() =>
            deleteMealPlanRecipe({
              variables: { id: mealPlanRecipe.id },
            })
          }
        />
      </HStack>
    </Box>
  );
}
