import { theme } from "native-base";

export const dayFormat = "ddd, MMM D";
export const dayStorageFormat = "YYYY-MM-DD";
export const standardMealNames = ["breakfast", "lunch", "dinner"];
export const colors = [
  theme.colors.green["300"],
  theme.colors.green["400"],
  theme.colors.green["500"],
  theme.colors.green["600"],
  theme.colors.green["700"],
];
