import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { MealPlanStackParamList } from "./MealPlanStack";
import { Box } from "native-base";
import { RecipeOnlySearchSidebar } from "../../components/mealplan/RecipeOnlySearchSidebar";
import React from "react";
import {
  GetItemsForRangeDocument,
  GetRecipeCoreWithIngredientsFragment,
  Meal_Plans_Recipes_Insert_Input,
  useGetMealPlanWithRecipesQuery,
  useInsertMealPlanRecipeItemSourcesMutation,
} from "../../generated/graphql";
import { dayStorageFormat } from "../../utils/constants";
import dayjs from "dayjs";

export function FindRecipe({
  navigation,
  route: {
    params: { mealPlanId },
  },
}: StackScreenProps<MealPlanStackParamList, "FindRecipe">) {
  const { data } = useGetMealPlanWithRecipesQuery({
    variables: { id: mealPlanId },
  });
  const startDay = data?.meal_plans_by_pk?.starts_on
    ? dayjs(data?.meal_plans_by_pk?.starts_on)
    : dayjs();
  const [insertMealPlanRecipeItemSources, { data: insertData }] =
    useInsertMealPlanRecipeItemSourcesMutation();

  // TODO this whole function is duplicated from the MealPlanRecipeSelection
  //  we can probably refactor this INTO the RecipeOnlySearchSidebar or some
  //  wrapper that handles search + add
  const handleRecipeSelect = (recipe: GetRecipeCoreWithIngredientsFragment) => {
    const ingredients = recipe.recipe_ingredients.map((i) => ({
      recipe_ingredient_id: i.id,
      desired_at: startDay.format(dayStorageFormat),
    }));
    const mealPlansRecipesInsertInput: Meal_Plans_Recipes_Insert_Input = {
      meal_plan_id: mealPlanId,
      recipe_id: recipe.id,
      day: startDay,
      item_sources: {
        data: ingredients, // TODO does this also set meal_plan_recipe_id ?
      },
    };
    return insertMealPlanRecipeItemSources({
      variables: { meal_plan_recipes: [mealPlansRecipesInsertInput] },
      refetchQueries: [GetItemsForRangeDocument],
    }).then(() => navigation.pop());
  };

  return (
    <Box m={2} h="100%">
      <RecipeOnlySearchSidebar onPress={handleRecipeSelect} />
    </Box>
  );
}
