import { Input, Spinner } from "native-base";
import React, { useCallback, useState } from "react";
import { ITagProps } from "native-base/src/components/composites/Tag/types";
import { debounce } from "lodash";

type Props = {
  initialValue?: string;
  propName: string;
  // eslint-disable-next-line
  useMutation: any;
  recipeId: string;
} & ITagProps;

export function SmartInput(props: Props) {
  const { initialValue, useMutation, recipeId, propName, ...remaining } = props;
  const [value, setValue] = useState(initialValue || "");

  const [updateValue, { loading: updateValueLoading }] = useMutation();

  function handleDebounceFn(inputValue: string) {
    if (inputValue.trim() !== "") {
      updateValue({ variables: { id: recipeId, [propName]: inputValue } });
    }
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  function handleChange(text: string) {
    setValue(text);
    debounceFn(text);
  }

  return (
    <Input
      {...remaining}
      value={value}
      onChangeText={handleChange}
      InputRightElement={updateValueLoading ? <Spinner mr={2} /> : undefined}
    />
  );
}
