import React from "react";
import { Box, Button, Icon, HStack, VStack, IconButton } from "native-base";
import { Ionicons } from "@expo/vector-icons";

type ShoppingListHeaderProps = {
  title: JSX.Element;
  left: JSX.Element;
};

export function ShoppingListHeader({
  title,
  left,
}: ShoppingListHeaderProps): JSX.Element {
  return (
    <Box>
      <VStack alignItems="center" space={2}>
        {title}
        <HStack>
          {left}
          <Button
            size="sm"
            variant="outline"
            width="160"
            leftIcon={<Icon as={Ionicons} name="add" size="sm" />}
          >
            Add Item
          </Button>
          {/* a placeholder to keep the other things balanced/centered.
            possible to add another button here in the future but for now ¯\_(ツ)_/¯
          */}
          <IconButton icon={<Icon as={Ionicons} />} disabled />
        </HStack>
      </VStack>
    </Box>
  );
}
