import { MealPlanCoreFragment } from "../../generated/graphql";
import dayjs from "dayjs";

export function getMealPlanTitle(
  mealPlan?: MealPlanCoreFragment | null
): string {
  if (mealPlan?.name && mealPlan?.starts_on && mealPlan.ends_on) {
    const starts = dayjs(mealPlan.starts_on);
    const ends = dayjs(mealPlan.ends_on);
    return `${mealPlan.name} (${starts.format("MM/DD")} - ${ends.format(
      "MM/DD"
    )})`;
  }

  if (mealPlan?.starts_on && mealPlan.ends_on) {
    const starts = dayjs(mealPlan.starts_on);
    const ends = dayjs(mealPlan.ends_on);
    return `${starts.format("MM/DD")} - ${ends.format("MM/DD")}`;
  }

  if (mealPlan?.starts_on) {
    const starts = dayjs(mealPlan.starts_on);
    return `Meal plan for ${starts.format("MM/DD")}`;
  }

  return "Unnamed mealplan";
}
