import React, { useEffect, useMemo, useState } from "react";
import { ListRenderItemInfo, View } from "react-native";
import { Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Pressable,
  ScrollView,
  Text,
  useDisclose,
  VStack,
} from "native-base";
import { SwipeListView } from "react-native-swipe-list-view";
import { ShoppingListItemRow } from "../../components/shoppinglist/ShoppingListItemRow";
import { sum } from "lodash";
import { ShoppingListRootStackParamList } from "./ShoppingListStack";
import { useGetItemsForRangeLazyQuery } from "../../generated/graphql";
import { ShoppingListItemWithSources } from "../../utils/conversions";
import { useGroupedItems } from "../../utils/shoppinglist";
import { ShoppingListItemActionSheet } from "../../components/shoppinglist/ShoppingListItemActionSheet";
import dayjs from "dayjs";
import { ShoppingListHeader } from "../../components/shoppinglist/ShoppingListHeader";
import { SelectableCalendar } from "../../components/calendar/SelectableCalendar";
import { PopoverWrapper } from "../../components/popover/PopoverWrapper";

export function ShoppingListDetails({
  navigation,
}: StackScreenProps<ShoppingListRootStackParamList, "ShoppingListDetails">) {
  const [showCompleted, setShowCompleted] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().startOf("day").day(0)
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(startDate.add(7, "days"));

  // TODO expand items so that we can pull from a recipe_ingredient OR an adhoc item. probably new table for adhoc so we can add categories?
  const [getItemsForRange, { loading, error, data }] =
    useGetItemsForRangeLazyQuery();

  useEffect(() => {
    getItemsForRange({ variables: { start: startDate, end: endDate } });
  }, [startDate, endDate]);

  const { items } = useMemo(
    () => useGroupedItems(data?.item_sources, showCompleted),
    [data?.item_sources, showCompleted]
  );

  // action sheet
  const { isOpen, onOpen, onClose } = useDisclose();
  const [actionSheetItem, setActionSheetItem] =
    useState<ShoppingListItemWithSources>();

  React.useEffect(() => {
    const remaining = sum(
      items.map((i) => i.data.filter((x) => !x.completed).length)
    );
    const remainingText = remaining > 0 ? `(${remaining} remaining)` : "";
    const startFormatted = startDate.format("MMM D");
    const endFormatted = endDate.format("MMM D");
    navigation.setOptions({
      headerTitleAlign: "center",
      headerStyle: { height: 90 }, // stupid header has to be adjusted here with a height value
      headerTitle: () => (
        //  TODO in the calendar somehow show the number of meal plans being interacted with
        //      like a number under each day
        <ShoppingListHeader
          left={
            <IconButton
              onPress={() => setShowCompleted(!showCompleted)}
              icon={
                <Icon as={Ionicons} name={showCompleted ? "eye" : "eye-off"} />
              }
            />
          }
          title={
            // TODO quick select from meal plans that intersect with this displayed month
            <PopoverWrapper
              headerText={"Select Range"}
              popoverTrigger={(triggerProps) => {
                return (
                  <Pressable {...triggerProps}>
                    <Heading mt="2" size="md">
                      {`${startFormatted} to ${endFormatted} ${remainingText}`}
                    </Heading>
                  </Pressable>
                );
              }}
            >
              <HStack space={2}>
                <Button
                  size="sm"
                  onPress={() => {
                    setStartDate(dayjs().startOf("week"));
                    setEndDate(dayjs().endOf("week"));
                  }}
                >
                  This week
                </Button>
                <Button
                  size="sm"
                  onPress={() => {
                    setStartDate(dayjs().add(1, "week").startOf("week"));
                    setEndDate(dayjs().add(1, "week").endOf("week"));
                  }}
                >
                  Next week
                </Button>
              </HStack>
              <SelectableCalendar
                onSelectStartDate={setStartDate}
                onSelectEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            </PopoverWrapper>
          }
        />
      ),
    });
  }, [showCompleted, startDate, endDate, navigation, items]);

  if (loading) {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  }
  if (error) {
    console.error(error);
    return (
      <View>
        <Text>Error!</Text>
      </View>
    );
  }

  // TODO maybe make expandable to show how many meals this comes from
  const renderItem = ({
    item,
  }: ListRenderItemInfo<ShoppingListItemWithSources>) => (
    <ShoppingListItemRow
      key={item.name}
      item={item}
      onMorePress={(item) => {
        setActionSheetItem(item);
        onOpen();
      }}
    />
  );

  const renderHiddenItem = () => (
    <HStack flex="1" pl="2">
      <Pressable
        w="70"
        ml="auto"
        bg="coolGray.200"
        justifyContent="center"
        // onPress={() => closeRow(rowMap, data.item.id)}
        _pressed={{
          opacity: 0.5,
        }}
      >
        <VStack alignItems="center" space={2}>
          <Icon
            as={Entypo}
            name="dots-three-horizontal"
            size="xs"
            color="coolGray.800"
          />
          <Text fontSize="xs" fontWeight="medium" color="coolGray.800">
            More
          </Text>
        </VStack>
      </Pressable>
      <Pressable
        w="70"
        bg="red.500"
        justifyContent="center"
        // onPress={() => deleteRow(rowMap, data.item.id)}
        _pressed={{
          opacity: 0.5,
        }}
      >
        <VStack alignItems="center" space={2}>
          <Icon as={MaterialIcons} name="delete" color="white" size="xs" />
          <Text color="white" fontSize="xs" fontWeight="medium">
            Delete
          </Text>
        </VStack>
      </Pressable>
    </HStack>
  );

  return (
    <Box flex={1}>
      {!!actionSheetItem && (
        <ShoppingListItemActionSheet
          item={actionSheetItem}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <ScrollView showsVerticalScrollIndicator={false}>
        {items.length <= 0 && (
          <VStack alignSelf="center" mt="8" space={2}>
            <Heading size="sm">
              Nothing on the list? Try changing dates or
            </Heading>
            <Button
              onPress={() =>
                // @ts-ignore look into NavigatorScreenParams
                navigation.navigate("MealPlan", { screen: "MealPlanList" })
              }
            >
              Create a meal plan
            </Button>
          </VStack>
        )}
        <Box bg="white" safeArea flex="1">
          <SwipeListView
            initialNumToRender={10}
            useSectionList={true}
            sections={items}
            extraData={data?.item_sources}
            renderItem={renderItem}
            onRefresh={() =>
              getItemsForRange({
                variables: { start: startDate, end: endDate },
              })
            }
            refreshing={loading}
            renderHiddenItem={renderHiddenItem}
            renderSectionHeader={({ section: { title } }) => (
              <Center bg="primary.500">
                <Heading fontSize="lg" mt="1" pb="1" color="warmGray.50">
                  {title}
                </Heading>
              </Center>
            )}
            rightOpenValue={-130}
          />
        </Box>
      </ScrollView>
    </Box>
  );
}
