import { Input } from "native-base";

type MaskedInputProps = {
  mask: string; //"xx/xx/xxxx"
  value: string;
  onChangeText: (text: string) => void;
};

export function MaskedInput({ mask, value, onChangeText }: MaskedInputProps) {
  const transform = (text: string) => {
    const maxLength = mask.length;
    const delimiterIndices = Array.from(mask).reduce<number[]>(
      (acc, curr, currIdx) => {
        if (curr === "/") {
          return [...acc, currIdx];
        }
        return acc;
      },
      []
    );

    const out = Array.from(text).reduce<string>((acc, curr, currIdx) => {
      if (delimiterIndices.includes(currIdx) && curr !== "/") {
        return acc + "/" + curr;
      }
      if (acc.length >= maxLength) {
        return acc;
      }
      return acc + curr;
    }, "");
    return out;
  };

  return (
    <Input value={value} onChangeText={(t) => onChangeText(transform(t))} />
  );
}
