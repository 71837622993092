import { ShoppingListItemWithSources } from "./conversions";
import { every, groupBy, sortBy } from "lodash";
import { ItemWithSourcesFragment } from "../generated/graphql";
import { SectionListData } from "react-native";

export function useGroupedItems(
  rawItems?: ItemWithSourcesFragment[],
  showCompleted?: boolean
) {
  // keyed by item name
  const sourcesByName = groupBy(
    rawItems,
    (value) =>
      value.recipe_ingredient?.ingredient?.name ||
      value.recipe_ingredient?.raw_text
  );

  // making the top level object easier to work with
  const aggregateItemsWithSources: ShoppingListItemWithSources[] = Object.keys(
    sourcesByName
  )
    .map((key) => {
      const sources = sourcesByName[key];
      return {
        name: key,
        item_sources: sources,
        categoryName: sources[0].recipe_ingredient?.ingredient?.category?.name,
        completed: every(sources.map((s) => !!s.completed_at)),
        ingredient_id: sources[0].recipe_ingredient?.ingredient?.id,
      };
    })
    .filter((i) => (showCompleted ? true : !i.completed));

  // sort by name
  const sortedItems = sortBy(aggregateItemsWithSources, [(i) => i.name]);

  const itemsByCategory = groupBy(
    sortedItems,
    (i) => i.categoryName || "other"
  );

  const unsortedItems: ReadonlyArray<
    SectionListData<ShoppingListItemWithSources>
  > = Object.entries(itemsByCategory).map(([key, value]) => ({
    title: `${key} (${value.length})`,
    data: value,
  }));
  // TODO add sort index ability for a shopping list categories.
  const items = sortBy(unsortedItems, [(i) => i.title]);

  return { items };
}
