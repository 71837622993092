import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
} from "native-base";
import {
  IngredientSearchFragment,
  StapleFragment,
  StaplesWithCategoryDocument,
  useDeleteStapleMutation,
  useInsertStapleMutation,
  useStaplesWithCategoryQuery,
} from "../../generated/graphql";
import { IngredientSearch } from "../ingredients/IngredientSearch";
import React, { useState } from "react";
import { Ionicons } from "@expo/vector-icons";

export function Staples() {
  const [ingredient, setIngredient] = useState<
    IngredientSearchFragment | undefined
  >();

  const { data, loading, error } = useStaplesWithCategoryQuery();
  const [insertStaple, { loading: insertStapleLoading }] =
    useInsertStapleMutation();
  const [deleteStaple, { loading: deleteStapleLoading }] =
    useDeleteStapleMutation();

  const handleDeleteStaple = (stapleId: string) => {
    deleteStaple({
      variables: { id: stapleId },
      refetchQueries: [StaplesWithCategoryDocument],
    });
  };

  const handleInsertStaple = () => {
    insertStaple({
      variables: { ingredient_id: ingredient?.id },
      refetchQueries: [StaplesWithCategoryDocument],
    });
    setIngredient(undefined);
  };

  const staples: StapleFragment[] = data?.staples || [];

  return (
    <VStack space={2}>
      <Heading size="md">Pantry Staples</Heading>
      <Text>Ingredients here won't be added to shopping lists.</Text>
      <HStack justifyContent="center" alignItems="center" space={2}>
        <IngredientSearch handleSelect={(i) => setIngredient(i)} />
        <Button
          onPress={handleInsertStaple}
          isLoading={insertStapleLoading}
          size="sm"
        >
          Add
        </Button>
      </HStack>
      <VStack space={2}>
        {loading && <Spinner />}
        {staples.map((staple) => (
          <HStack space={2} flex={1} justifyContent="space-between">
            <Text>{staple.ingredient.name}</Text>
            <IconButton
              variant="solid"
              size="xs"
              _icon={{
                as: Ionicons,
                name: "remove",
              }}
              isDisabled={deleteStapleLoading}
              onPress={() => handleDeleteStaple(staple.id)}
            />
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
}
