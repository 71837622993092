import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Pressable,
  Spinner,
  Text,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import React, { useMemo } from "react";
import {
  combine,
  displaySimplifiedItem,
  ShoppingListItemWithSources,
} from "../../utils/conversions";
import { some } from "lodash";
import { useUpdateCompletionStatusMutation } from "../../generated/graphql";

type ShoppingListItemRowProps = {
  item: ShoppingListItemWithSources;
  onMorePress: (item: ShoppingListItemWithSources) => void;
};

export function ShoppingListItemRow({
  item,
  onMorePress,
}: ShoppingListItemRowProps) {
  const rowText = useMemo(() => {
    const simplifiedItem = combine(item);
    return displaySimplifiedItem(simplifiedItem);
  }, [item.name]);

  const [updateCompletionStatusMutation, { loading }] =
    useUpdateCompletionStatusMutation();

  const updateCompletionStatus = (item: ShoppingListItemWithSources) => {
    console.log(item);
    const anyCompletedSet = some(
      item.item_sources.map((i) => !!i.completed_at)
    );
    return updateCompletionStatusMutation({
      variables: {
        _in: item.item_sources.map((i) => i.id),
        completed_at: anyCompletedSet ? null : new Date(),
      },
    });
  };

  return (
    <Box>
      <Pressable
        disabled={loading}
        onPress={() => updateCompletionStatus(item)}
        bg="white"
      >
        <Box pl="4" pr="5" py="4">
          <HStack alignItems="center" justifyContent="space-between" space={3}>
            {loading ? (
              <HStack space={4}>
                <Spinner />
                <Text>{rowText}</Text>
              </HStack>
            ) : (
              <Checkbox isChecked={item.completed} value={item.name}>
                <Text
                  mx="2"
                  strikeThrough={item.completed}
                  _light={{
                    color: item.completed ? "gray.400" : "coolGray.800",
                  }}
                  _dark={{
                    color: item.completed ? "gray.400" : "coolGray.50",
                  }}
                >
                  {rowText}
                </Text>
                {/*{item.shopping_list_item_stores.length > 0 && (*/}
                {/*  <HStack space={2}>*/}
                {/*    {item.shopping_list_item_stores.map((s) => (*/}
                {/*      <Badge>{s.store.name}</Badge>*/}
                {/*    ))}*/}
                {/*  </HStack>*/}
                {/*)}*/}
              </Checkbox>
            )}

            <Button
              onPress={() => onMorePress(item)}
              variant="ghost"
              leftIcon={
                <Icon
                  as={Entypo}
                  name="dots-three-horizontal"
                  size="xs"
                  color="coolGray.800"
                />
              }
            />
          </HStack>
        </Box>
      </Pressable>
      <Divider />
    </Box>
  );
}
