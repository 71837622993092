import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FlatList,
  HStack,
  Pressable,
  Text,
} from "native-base";
import {
  GetShoppingListItemDocument,
  GetStoresDocument,
  Store,
  useDeleteShoppingListItemStoreMutation,
  useGetShoppingListItemQuery,
  useGetStoresQuery,
  useInsertItemStoreMutation,
  useInsertNewStoreMutation,
} from "../../generated/graphql";
import { ListRenderItemInfo } from "react-native";
import { StackScreenProps } from "@react-navigation/stack/lib/typescript/src/types";
import { AddInput } from "../../components/AddInput";
import { ShoppingListRootStackParamList } from "./ShoppingListStack";

export function ItemStoreSelection({
  navigation,
  route,
}: StackScreenProps<ShoppingListRootStackParamList, "ItemStoreSelection">) {
  const itemId = route?.params?.itemId;

  if (!itemId) {
    navigation.navigate("ShoppingListHome");
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button onPress={() => navigation.pop()} mr={2}>
          Done
        </Button>
      ),
    });
  }, [navigation]);

  const { data: shoppingListItemData } = useGetShoppingListItemQuery({
    variables: { id: itemId },
  });

  const [insertItemStore] = useInsertItemStoreMutation();
  const [deleteItemStore] = useDeleteShoppingListItemStoreMutation();

  const { data: allStoreData } = useGetStoresQuery();

  const [addNewStore] = useInsertNewStoreMutation({
    refetchQueries: [{ query: GetStoresDocument }],
  });

  function insertNewStore(newStore: string) {
    return addNewStore({ variables: { name: newStore, icon_name: "🤖" } });
  }

  function renderHeader() {
    return <AddInput onSubmit={(newItemName) => insertNewStore(newItemName)} />;
  }

  const shoppingListItemStoreIds =
    shoppingListItemData?.shopping_list_item_by_pk?.shopping_list_item_stores.map(
      (s) => s.store_id
    ) || [];

  function onSelectStore(store: Store) {
    const rowToDelete =
      shoppingListItemData?.shopping_list_item_by_pk?.shopping_list_item_stores.filter(
        (s) => s.store_id === store.id
      );
    if (
      shoppingListItemStoreIds.indexOf(store.id) >= 0 &&
      rowToDelete.length > 0
    ) {
      return deleteItemStore({
        variables: { id: rowToDelete[0].id },
        refetchQueries: [
          { query: GetShoppingListItemDocument, variables: { id: itemId } },
        ],
      });
    } else {
      return insertItemStore({
        variables: { itemId: itemId, storeId: store.id },
        refetchQueries: [
          { query: GetShoppingListItemDocument, variables: { id: itemId } },
        ],
      });
    }
  }

  function renderItem({ item: store }: ListRenderItemInfo<Store>) {
    return (
      <Pressable onPress={() => onSelectStore(store)}>
        <Box
          borderBottomWidth="1"
          _dark={{
            borderColor: "gray.600",
          }}
          borderColor="coolGray.200"
          pl="4"
          pr="5"
          py="2"
        >
          <HStack space={3} justifyContent="start" alignItems="center">
            <Checkbox
              isChecked={shoppingListItemStoreIds.indexOf(store.id) >= 0}
              value={store.name}
            >
              <Text mx="2">{store.name}</Text>
            </Checkbox>
          </HStack>
        </Box>
      </Pressable>
    );
  }

  return (
    <Box>
      <FlatList
        ListHeaderComponent={renderHeader()}
        data={allStoreData?.store}
        renderItem={renderItem}
      />
    </Box>
  );
}
