import dayjs from "dayjs";
import { MarkedDates } from "react-native-calendars/src/types";

export function getAllDays(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
  // TODO rewrite more functionally
  const allDays = [];
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    allDays.push(currentDate);
    currentDate = currentDate.add(1, "day");
  }
  return allDays;
}

export function convertToMarkedDates(
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  color: string
): MarkedDates {
  const allDays = getAllDays(startDate, endDate);
  return allDays.reduce(
    (acc, curr, currIdx, original) => ({
      ...acc,
      [curr.format("YYYY-MM-DD")]: {
        startingDay: currIdx === 0,
        endingDay: currIdx === original.length - 1,
        selected: true,
        color: color,
      },
    }),
    {}
  );
}
