import React from "react";
import { NativeBaseProvider } from "native-base";
import "./App.css";
import { AppCore } from "./AppCore";
import { NhostReactProvider } from "@nhost/react";
import { nhost } from "./utils/nhost";
import { theme } from "./utils/colors";

const App = () => (
  <NativeBaseProvider theme={theme}>
    <NhostReactProvider nhost={nhost}>
      <AppCore />
    </NhostReactProvider>
  </NativeBaseProvider>
);

export default App;
