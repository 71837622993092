import {
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Stack,
  Text,
} from "native-base";
import {
  MealPlanCoreFragment,
  useGetMealPlansLazyQuery,
} from "../../generated/graphql";
import { SwipeListView } from "react-native-swipe-list-view";
import React, { useEffect, useState } from "react";
import { ListRenderItemInfo } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import { MealPlanStackParamList } from "./MealPlanStack";
import dayjs from "dayjs";
import { colors, dayFormat, dayStorageFormat } from "../../utils/constants";
import { orderBy } from "lodash";
import { Calendar } from "react-native-calendars";
import { getAllDays } from "../../components/calendar/calendarUtils";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import { MarkedDates } from "react-native-calendars/src/types";

type MealPlanListProps = {
  navigation: StackNavigationProp<MealPlanStackParamList, "MealPlanList">;
};

export function MealPlanList({ navigation }: MealPlanListProps) {
  const [visibleMonth, setVisibleMonth] = useState(dayjs());

  const [getMealPlans, { data, loading, error }] = useGetMealPlansLazyQuery();

  useEffect(() => {
    getMealPlans({
      variables: {
        startDay: visibleMonth.startOf("month"),
        endDay: visibleMonth.endOf("month"),
      },
    });
  }, [visibleMonth]);

  if (loading) {
    return (
      <Center w="64" h="20" rounded="md">
        <Text>Loading...</Text>
      </Center>
    );
  }
  if (error) {
    console.error(error);
    return (
      <Center w="64" h="20" rounded="md">
        <Text>Error!</Text>
      </Center>
    );
  }
  if (!data) {
    return <Box />;
  }

  const listData = orderBy(
    data?.meal_plans,
    ["starts_on", "ends_on"],
    ["desc", "desc"]
  );

  const colorMap = listData.reduce<{ [mealPlanId: string]: string }>(
    (acc, curr: MealPlanCoreFragment, i) => ({
      ...acc,
      [curr.id]: colors[i % 5],
    }),
    {}
  );

  const markedDates = listData
    .flatMap((mealPlan: MealPlanCoreFragment) => {
      const allDays = getAllDays(
        dayjs(mealPlan.starts_on),
        dayjs(mealPlan.ends_on)
      );
      const color = colorMap[mealPlan.id];
      return allDays.map((day) => ({ day, mealPlan, color }));
    })
    .reduce<MarkedDates>((acc, curr) => {
      const currKey = curr.day.format(dayStorageFormat);
      const startingDay = curr.day.isSame(dayjs(curr.mealPlan.starts_on));
      const endingDay = curr.day.isSame(dayjs(curr.mealPlan.ends_on));
      const previous = acc[currKey] || {};
      const previousPeriods = previous["periods"] || [];
      return {
        ...acc,
        [currKey]: {
          periods: [
            ...previousPeriods,
            {
              startingDay,
              endingDay,
              color: curr.color,
            },
          ],
        },
      };
    }, {});

  // TODO maybe only highlight options that overlap with today

  // TODO show more differentiating data so it's easy to understand
  //  maybe things like number of recipes, recipe category tag counts
  // and show the name if it's set? but probably want to make it editable first
  const renderItem = ({ item }: ListRenderItemInfo<MealPlanCoreFragment>) => (
    <Box mt={2} mx={2}>
      <Pressable
        onPress={() => {
          return (item.meal_plan_recipes_aggregate.aggregate?.count ?? 0) > 0
            ? navigation.navigate("Meal Plan Recipe Selection", {
                mealPlanId: item.id,
              })
            : navigation.navigate("Meal Plan Wizard", {
                mealPlanId: item.id,
              });
        }}
        bg="white"
        rounded="lg"
      >
        <Box
          pl="4"
          pr="5"
          py="2"
          // backgroundColor={colorMap[item.id]}
        >
          <HStack alignItems="center" space={2}>
            <Box size="xs" bg={colorMap[item.id]} rounded="3xl" h={2} w={2} />
            <Text color="coolGray.800" _dark={{ color: "warmGray.50" }} bold>
              {dayjs(item.starts_on).format(dayFormat)} to{" "}
              {dayjs(item.ends_on).format(dayFormat)}
            </Text>
            {(item.meal_plan_recipes_aggregate.aggregate?.count ?? 0) <= 0 && (
              <IconButton
                size="xs"
                colorScheme={"warning"}
                icon={<Icon as={Feather} name="alert-triangle" size="xs" />}
              />
            )}
          </HStack>
        </Box>
      </Pressable>
      <Divider />
    </Box>
  );

  return (
    <Stack space={2} direction={{ base: "column", md: "row" }} flex={1}>
      <Box pl={{ md: 4 }} pt={{ md: 2 }}>
        <Calendar
          markingType="multi-period"
          markedDates={markedDates}
          onMonthChange={(date) => setVisibleMonth(dayjs(date.dateString))}
          renderArrow={(direction) => (
            <Icon as={MaterialIcons} name={`arrow-${direction}`} size="lg" />
          )}
          style={{
            borderRadius: 4,
            margin: 4,
          }}
        />
      </Box>
      <Box flex={1}>
        <SwipeListView
          data={listData}
          renderItem={renderItem}
          rightOpenValue={-130}
        />
      </Box>
    </Stack>
  );
}
