import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { ShoppingListStack } from "./pages/shoppinglist/ShoppingListStack";
import { RecipeListStack } from "./pages/recipe/RecipeStack";
import "./App.css";
import ExampleProfile from "./components/auth/ExampleProfile";
import { Icon, Spinner } from "native-base";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { MealPlanStack } from "./pages/mealplan/MealPlanStack";
import { useAuthenticationStatus } from "@nhost/react";
import { nhost } from "./utils/nhost";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { Login } from "./pages/auth/Login";
import { SignUp } from "./components/auth/SignUp";
import { createStackNavigator } from "@react-navigation/stack";

const linking = {
  prefixes: [],
  // config: {
  //     screens: {
  //         Home: 'home',
  //         Details: 'details'
  //     }
  // }
};

export type RootTabParamList = {
  "Shopping List": undefined;
  "Recipe Details": undefined;
  MealPlan: undefined;
  Recipes: undefined;
  profile: undefined;
};

export type UnauthenticatedParamList = {
  login: undefined;
  signup: undefined;
  "forgot password": undefined;
};

const Tab = createBottomTabNavigator<RootTabParamList>();
const Stack = createStackNavigator<UnauthenticatedParamList>();

export const AppCore = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <NhostApolloProvider nhost={nhost}>
      <NavigationContainer linking={linking}>
        {isAuthenticated ? (
          <Tab.Navigator
            initialRouteName="Recipes"
            screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                if (route.name === "Recipes") {
                  return (
                    <Icon
                      as={MaterialCommunityIcons}
                      name={
                        focused
                          ? "food-takeout-box"
                          : "food-takeout-box-outline"
                      }
                      size={size}
                      color={color}
                    />
                  );
                } else if (route.name === "profile") {
                  return (
                    <Icon
                      as={Ionicons}
                      name={focused ? "ios-settings" : "ios-settings-outline"}
                      size={size}
                      color={color}
                    />
                  );
                } else if (route.name === "MealPlan") {
                  return (
                    <Icon
                      as={Ionicons}
                      name={focused ? "calendar" : "calendar-outline"}
                      size={size}
                      color={color}
                    />
                  );
                } else if (route.name === "Shopping List") {
                  return (
                    <Icon
                      as={Ionicons}
                      name={focused ? "list-circle" : "list-circle-outline"}
                      size={size}
                      color={color}
                    />
                  );
                }
              },
            })}
          >
            <Tab.Screen name="Shopping List" component={ShoppingListStack} />
            <Tab.Screen name="MealPlan" component={MealPlanStack} />
            <Tab.Screen name="Recipes" component={RecipeListStack} />
            <Tab.Screen
              name="profile"
              component={ExampleProfile}
              options={{ title: "Profile" }}
            />
          </Tab.Navigator>
        ) : (
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
            }}
          >
            <Stack.Screen name="login" component={Login} />
            <Stack.Screen name="signup" component={SignUp} />
          </Stack.Navigator>
        )}
      </NavigationContainer>
    </NhostApolloProvider>
  );
};
