import { ItemWithSourcesFragment } from "../generated/graphql";
import { Dictionary, groupBy, isNil, sortBy } from "lodash";
import * as fracty from "fracty";

export type ShoppingListItemWithSources = {
  name: string;
  categoryName: string | null | undefined;
  completed: boolean;
  item_sources: ItemWithSourcesFragment[];
  ingredient_id: string;
};

export type SimplifiedItem = {
  name: string;
  quantities: ItemAmounts[];
};

type ItemAmounts = {
  amount: number;
  measure: string | undefined | null;
  multiplier: number;
};

export function combine(item: ShoppingListItemWithSources): SimplifiedItem {
  // this just makes it easier to traverse
  // output is [{amount: 1, measure: tbsp}, {amount: 3, measure: cup}]
  const flattenedItems = item.item_sources.map((x) => ({
    amount: x.recipe_ingredient?.amount,
    measure: x.recipe_ingredient?.measure,
    multiplier: x.meal_plan_recipe?.multiplier || 1,
  }));

  // create a map keyed by measure
  // {
  // tbsp: [{amount: 1, measure: tbsp}, {amount: 3, measure: TBSP}],
  // cup: [{amount: 3, measure: cup}]
  // }
  const itemsByMeasure: Dictionary<ItemAmounts[]> = groupBy(
    flattenedItems,
    (flat) => flat.measure?.toLowerCase()
  );

  // taking the above example of tbsp, the output here is
  // [{amount: 4, measure: tbsp}]
  const itemAountArray = Object.values(itemsByMeasure).map(
    (arrayOfItemAmounts) => {
      return arrayOfItemAmounts.reduce<ItemAmounts>(
        (prev, curr) => {
          const total = prev.amount + curr.amount * curr.multiplier;
          return {
            ...prev,
            amount: total,
            measure: curr.measure?.toLowerCase(),
          };
        },
        {
          amount: 0,
          measure: undefined,
          multiplier: 1,
        }
      );
    }
  );

  return {
    name: item.name || "unknown",
    quantities: itemAountArray,
  };
}

export function displayItemSourceDetails(
  item: ShoppingListItemWithSources
): string[] {
  return sortBy(item.item_sources, (i) => i.recipe_ingredient?.amount).map(
    (source) =>
      [
        displayFraction(source.recipe_ingredient?.amount),
        source.recipe_ingredient?.measure,
        "for",
        source.recipe_ingredient?.recipe.name,
        source.meal_plan_recipe?.multiplier
          ? "x " + source.meal_plan_recipe?.multiplier
          : undefined,
      ]
        .filter((i) => !isNil(i))
        .join(" ")
  );
}

export function displaySimplifiedItem(item: SimplifiedItem): string {
  const measureText = item.quantities.map((q) => {
    const amount = fracty(q.amount);
    if (q.measure) {
      return `${amount} ${q.measure}`;
    } else {
      return `${amount}`;
    }
  });

  return `${measureText.join(" and ")} ${item.name}`;
}

export function fractionConversion(fraction?: string): number {
  if (!fraction || fraction?.length <= 0) {
    return NaN;
  }

  if (fraction.length >= 3 && isFraction(fraction)) {
    const parts = fraction.split("/");
    return filterInt(parts[0]) / filterInt(parts[1]);
  }
  return Number(fraction);
}

function isFraction(maybeFraction: string): boolean {
  const parts = maybeFraction.split("/");
  if (parts.length !== 2) {
    return false;
  }
  return (
    Number.isInteger(Number(parts[0])) && Number.isInteger(Number(parts[1]))
  );
}

function filterInt(value: string): number {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
}

export function displayFraction(decimal: number): string {
  return fracty(decimal);
}
