import { RecipeIngredientWithItemSourcesFragment } from "../generated/graphql";
import dayjs from "dayjs";

export function isOnList(
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  item: RecipeIngredientWithItemSourcesFragment
): boolean {
  return (
    item.item_sources.filter((i) => {
      const itemDay = dayjs(i.desired_at);
      return (
        itemDay.isSame(startDate, "day") ||
        (itemDay.isAfter(startDate) && itemDay.isBefore(endDate))
      );
    }).length > 0
  );
}
