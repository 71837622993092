import { MealPlanRecipeCoreFragment } from "../../generated/graphql";
import { Dictionary, groupBy, last, range, sortBy } from "lodash";
import dayjs from "dayjs";

export type DailyRecipesByCategory = {
  [day: string]: {
    [category: string]: MealPlanRecipeCoreFragment[];
  };
};

export function dailyRecipesByCategory(
  data: MealPlanRecipeCoreFragment[]
): DailyRecipesByCategory {
  const dailyRecipes = groupBy(data, (x) => x.day);
  return Object.entries(dailyRecipes).reduce((acc, [dateKey, recipes]) => {
    const c = groupBy(recipes, (x) => x.category);
    return {
      ...acc,
      [dateKey]: c,
    };
  }, {});
}

/**
 * For each category, generate a 2D array for recipes across days.
 * @param data the recipes to pack
 * @param startDay the start day for packing to account for gaps
 * @param endDay the end day for packing to account for gaps
 */
export function recipesForRendering(
  data: MealPlanRecipeCoreFragment[],
  startDay: dayjs.Dayjs,
  endDay: dayjs.Dayjs
) {
  const categoryRecipes: Dictionary<MealPlanRecipeCoreFragment[]> = groupBy(
    data,
    (x) => x.category || "uncategorized"
  );
  return Object.entries(categoryRecipes).reduce<Dictionary<PlannerData[][]>>(
    (acc, [currKey, currValue]) => {
      return {
        ...acc,
        [currKey]: generateRow(currValue, startDay, endDay),
      };
    },
    {}
  );
}

export type PlannerData = {
  mealPlanRecipeId?: string;
  duration: number;
  multiplier: number;
  category: string;
  day: string;
  gap?: boolean;
};

export function toPlannerData(data: MealPlanRecipeCoreFragment): PlannerData {
  return {
    mealPlanRecipeId: data.id,
    duration: data.duration,
    multiplier: data.multiplier,
    category: data.category || "unknown",
    day: data.day,
  };
}

/**
 * Generates a 2D array of recipes and adds gaps to the start
 * or end as necessary.
 * @param data the recipes to be reorganized
 * @param startDay the start day for calculating prepending gaps
 * @param endDay the end day for calculating any tail gaps
 */
export function generateRow(
  data: MealPlanRecipeCoreFragment[],
  startDay: dayjs.Dayjs,
  endDay: dayjs.Dayjs
) {
  // assumption: all data is for the same category
  // now we have to generate a 2d array with gaps added as necessary
  const sortedData = sortBy(data, (d) => dayjs(d.day).unix()).map((d) =>
    toPlannerData(d)
  );

  // keep track of a lookup for covered date -> row index
  // then, when looking to add a new date, check if there's an entry
  // if an entry exists, we know we must go to the next row index
  // if an entry doesn't exist, we know we can use index zero
  const rowLookup = new Map<string, number>();

  // find the first dimension where there is no recipe starting or ending on this day
  // determine if there is a gap between this day and the previous recipe entry
  // if there is a gap, add the gap before the new recipe
  // add the new recipe to the available dimension
  // ensure each row has correct length by maybe adding one more gap at the end
  return sortedData.reduce<PlannerData[][]>((acc, curr, currIdx, orig) => {
    const currDay = dayjs(curr.day);
    const currDayFormatted = currDay.format("YYYY-MM-DD");

    // don't render for recipes starting before startDay
    // TODO maybe need to render the back half though
    if (currDay.isBefore(startDay)) {
      return acc;
    }

    // base case: there has been nothing accumulated anywhere
    if (acc.length === 0) {
      if (currDay.isAfter(startDay)) {
        const diff = Math.abs(startDay.diff(currDay, "days"));
        const gap = {
          duration: diff,
          multiplier: 1,
          category: curr.category,
          day: startDay.format("YYYY-MM-DD"),
          gap: true,
        };
        acc.push([gap, curr]);
      } else {
        // edge case: if there is only a single object, we have to add gaps
        // during the base case to the end
        const endOfCurrent = currDay.add(curr.duration - 1, "days");
        if (endOfCurrent.isBefore(endDay) && currIdx === orig.length - 1) {
          const diff = Math.abs(endDay.diff(endOfCurrent, "days"));
          const gap = {
            duration: diff,
            multiplier: 1,
            category: curr.category,
            day: endOfCurrent.add(1, "day").format("YYYY-MM-DD"),
            gap: true,
          };
          acc.push([curr, gap]);
        } else {
          acc.push([curr]);
        }
      }
      range(curr.duration - 1).forEach((i) => {
        rowLookup.set(currDay.add(i, "days").format("YYYY-MM-DD"), 0);
      });
      return acc;
    }

    const idx = rowLookup.has(currDayFormatted)
      ? rowLookup.get(currDayFormatted)! + 1
      : 0;

    // safety check that the row exists
    if (idx > acc.length - 1) {
      acc.push([]);
    }

    const selectedRow = acc[idx];

    if (selectedRow.length > 0) {
      // if there are other elements in the row, find the last element
      const lastItem = last(selectedRow)!;
      const lastItemDayEnd = dayjs(lastItem.day).add(
        lastItem.duration - 1,
        "days"
      );
      if (lastItemDayEnd.add(1, "days").isSame(currDay)) {
        // If the last item added ends exactly on the day before the current item starts, then just append it directly
        selectedRow.push(curr);
        rowLookup.set(currDayFormatted, idx);
      } else if (lastItemDayEnd.isSame(currDay)) {
        // if the last item has overlap on the same day, we have to add it to a new row
        // and account for starting gaps
        if (currDay.isAfter(startDay)) {
          const diff = Math.abs(startDay.diff(currDay, "days"));
          const gap = {
            duration: diff,
            multiplier: 1,
            category: curr.category,
            day: startDay.format("YYYY-MM-DD"),
            gap: true,
          };
          acc.push([gap, curr]);
        } else {
          acc.push([curr]);
        }
        range(curr.duration - 1).forEach((i) => {
          rowLookup.set(currDay.add(i, "days").format("YYYY-MM-DD"), 0);
        });
      } else {
        // If the current item is guaranteed after, then we have to account for padding
        // and then add this to the same row
        const diff = Math.abs(
          lastItemDayEnd.add(1, "days").diff(currDay, "days")
        );
        const gapDay = lastItemDayEnd.add(1, "days").format("YYYY-MM-DD");
        selectedRow.push(
          {
            duration: diff,
            multiplier: 1,
            category: curr.category,
            day: gapDay,
            gap: true,
          },
          curr
        );
        rowLookup.set(currDayFormatted, idx);
      }
    } else {
      // if this is the first element in a row, add it and account for starting gaps
      if (currDay.isAfter(startDay)) {
        const diff = Math.abs(startDay.diff(currDay, "days"));
        const gap = {
          duration: diff,
          multiplier: 1,
          category: curr.category,
          day: startDay.format("YYYY-MM-DD"),
          gap: true,
        };
        selectedRow.push(gap, curr);
      } else {
        selectedRow.push(curr);
      }
      range(curr.duration - 1).forEach((i) => {
        rowLookup.set(currDay.add(i, "days").format("YYYY-MM-DD"), 0);
      });
    }

    // if we're operating on the last item, every row needs to be padded with a gap at the end
    if (orig.length === currIdx + 1) {
      acc.forEach((row) => {
        const lastRowItem = last(row)!;
        const lastRowDay = dayjs(lastRowItem.day).add(
          lastRowItem.duration - 1,
          "days"
        );
        const diff = Math.abs(lastRowDay.diff(endDay, "days"));
        if (diff > 0) {
          row.push({
            duration: diff,
            multiplier: 1,
            category: curr.category,
            day: lastRowDay.add(1, "days").format("YYYY-MM-DD"),
            gap: true,
          });
        }
      });
    }

    return acc;
  }, []);
}
