import { HStack, Icon, IconButton } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import React, { useState } from "react";
import { useUpdateRecipeRatingMutation } from "../../generated/graphql";
import { range } from "lodash";

type StarRatingProps = {
  rating?: number; // out of 5. number of stars to show.
  recipeId?: string; // if provided, gives a way to update the rating.
  size?: string; // if provided, re-size stars.
};

export const StarRating = ({ rating = 0, size, recipeId }: StarRatingProps) => {
  const [updateRating] = useUpdateRecipeRatingMutation();
  const [isHovered, setIsHovered] = useState(-1);

  return (
    <HStack>
      {range(5).map((i) => (
        <IconButton
          key={`star-${i}`}
          icon={
            <Icon
              as={AntDesign}
              name={i <= rating - 1 ? "star" : "staro"}
              color={i <= rating - 1 ? "yellow.400" : "coolGray.800"}
              size={size}
              style={{
                transform: [{ scale: recipeId && isHovered === i ? 1.2 : 1 }],
              }}
            />
          }
          _hover={{
            bg: undefined,
          }}
          disabled={!recipeId}
          onHoverIn={() => !!recipeId && setIsHovered(i)}
          onHoverOut={() => !!recipeId && setIsHovered(-1)}
          onPress={() => {
            return recipeId
              ? updateRating({
                  variables: { rating: i + 1, id: recipeId },
                })
              : Promise.resolve();
          }}
        />
      ))}
    </HStack>
  );
};
