import { Button, HStack, Icon } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import { RecipeIngredientWithItemSourcesFragment } from "../../generated/graphql";
import { EditRecipeIngredient } from "../ingredients/EditRecipeIngredient";

type Props = {
  item: RecipeIngredientWithItemSourcesFragment;
  loading: boolean;
  onPressDelete: (item: RecipeIngredientWithItemSourcesFragment) => void;
};

export function RecipeIngredientRowEdit({
  item,
  loading,
  onPressDelete,
}: Props) {
  return (
    <HStack w="100%" space="xs" alignItems="center" key={item.id} pb="1">
      <EditRecipeIngredient
        recipeIngredientId={item.id}
        initialAmount={item.amount}
        initialMeasure={item.measure || ""}
        initialIngredient={item.ingredient}
        initialPreparation={item.preparation || ""}
      />
      <Button
        colorScheme="secondary"
        onPress={() => onPressDelete(item)}
        disabled={loading}
        startIcon={
          <Icon as={MaterialIcons} name="delete" color="white" size="xs" />
        }
      />
    </HStack>
  );
}
